@keyframes equalizer1 {
    0% {
      height:12px;
    }
    50% {
      height:4px;
    }
    100% {
      height:12px;
    }
}
@keyframes equalizer2 {
  0% {
    height:2px;
  }
  50% {
    height:10px;
  }
  100% {
    height:4px;
  }
}
@keyframes equalizer3 {
  0% {
    height:7px;
  }
  50% {
    height:2px;
  }
  100% {
    height:7px;
  }
}

html.scroll-lock body {
  overflow:hidden;
}
html.scroll-lock.vm,
html.scroll-lock.vm body {
  position:fixed;
  top:0;
  width: 100vw;
  height: 100vh;
  z-index:-1;
  -webkit-overflow-scrolling:auto;
}


.contents,.o_inner {
  overflow:hidden;
}

.o_tabmenu {
  z-index:150;
  position:fixed;
  top:0;
  left:0;
  height:100%;
  box-sizing:border-box;
  width:100%;
  overflow-y:auto;
  overflow-x:hidden;

  .vp & .scroll-inner {
    &>div {transform:none !important;transition:none !important;}
  }

  .inner {
    position:relative;
    top:0;
    left:50%;
    transform:translateX(-50%);
    box-sizing:border-box;
    width:100%;
    max-width:1100px;
    padding:240px 0 80px 120px;
  }

  .bg {
    content:'';
    display:block;
    position:absolute;
    z-index:-1;
    top:0;
    left:50%;
    margin-left:-580px;
    width:1540px;
    max-height:100%;
    height:100%;
    background:#fc0101;
    transform:scaleY(0);
    transform-origin:top;
    transition:transform .3s;
  }

  .btn_close {
    opacity:0;
    position:absolute;
    left:120px;
    top:50px;
    display:block;
    width:auto;
    color:$color-white;
    font-size:46px;
    transition:opacity .3s;

    .vp &:hover,.vp &:focus {
      -webkit-text-stroke: 1px $color-white;
      -webkit-text-fill-color: transparent;

      .ie & {opacity:.7;}
    }
  }

  ul li {
    font-size:86px;
    line-height:100px;
    font-weight:700;
    letter-spacing:-0.01em;
    opacity:0;
    transform:translateX(-20px);
    transition:transform .8s, opacity .5s .1s;

    $elements: 6;
    @for $i from 0 to $elements {
      &:nth-child(#{$i + 1}) {
        transition-delay: #{$i*0.1}s;
      }
    }

    & + li {
      margin-top:10px;
    }

    a {
      display:inline-block;
      color:$color-white;
    }

    sup {
      display:inline-block;
      font-size:22px;
      line-height:1;
      vertical-align:top;
      font-weight:500;
    }

    &.on {
      a {
        color:$color-black;
        transition: color .2s;
      }
    }

    .vp & {
      &:not(.on) a:hover,
      &:not(.on) a:focus {
        -webkit-text-stroke: 1px $color-white;
        -webkit-text-fill-color: transparent;

        sup {
          -webkit-text-stroke: 0;
          -webkit-text-fill-color: $color-white;
        }
      }
    }
    .vp.ie & {
      &:not(.on) a:hover,
      &:not(.on) a:focus {
        opacity:.7;
      }
    }
  }

  &.open {
    .bg {
      transform:scaleY(1);
      transition:transform .3s,background .8s;
    }
    .btn_close {opacity:1;}

    ul li {
      opacity:1;
      transform:translateX(0);
    }
  }

  &.close {
    .bg {
      transform:scaleY(0);
      transition:transform .1s .6s;
    }
    .btn_close {opacity:0;}
    ul li{
      opacity:0;
      transform:translateX(15px);
    }
  }

  .vm & {
    z-index:200;
    left:0;
    right:0;
    min-width:auto;
    width:100%;
    transform:none;
    overflow:hidden;

    .scroll-inner {
      z-index:2;
      position:absolute;
      top:0;
      bottom:0;
      right:0;
      left:0;
      width:100%;
      height:100%;
      max-height:100%;
      overflow-y: auto;
      overflow-x: hidden;
      -webkit-overflow-scrolling:touch;
      box-sizing:border-box;
    }
    .inner {
      left:0;
      right:0;
      bottom:0;
      padding:0;
      height:100%;
      transform:none;
    }
    .bg {z-index:1;height:100vh;max-height:none;}

    .btn_close {
      font-size:26px;
      left:43px;
      top:40px;
      font-weight:700;
    }

    ul {
      min-height:100%;
      padding:130px 20px 40px 43px;
      box-sizing:border-box;
    }
    ul li{
      font-size:44px;
      line-height:48px;

      & + li {
        margin-top:36px;
      }

      span {
        display:block;
      }

      sup {
        display:inline-block;
        font-size:11px;
        line-height:1;
        vertical-align:top;
        margin-top: 5px;
        margin-left:7px;
      }

      &:not(.on) a.active {
        -webkit-text-stroke: 1px $color-white;
        -webkit-text-fill-color: transparent;
        sup {
          -webkit-text-stroke: 0;
          -webkit-text-fill-color: #fff;
        }
      }
    }
  }
}

.board_header {
  position:absolute;
  top:0;
  left:0;
  right:0;
  height:440px;
  max-width:1100px;
  width:100%;
  margin:0 auto;
  padding-top:110px;
  box-sizing:border-box;

  .scroll_progress {
    opacity:0;
    position:fixed;
    z-index:1;
    top:178px;
    right:0;
    width:100%;
    height:1px;
    background:#f0f0f0;
    overflow:hidden;

    .bar {
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      background:$color-black;
      transform:translateX(-100%);
      // transition:transform .3s ease-out;
    }
  }


  .header_minify & {
    z-index:99;
    position:fixed;
    padding-top:24px;
    height:178px;

    &:after {
      content:'';
      z-index:-1;
      position:absolute;
      top:0;
      left:50%;
      margin-left:-960px;
      display:block;
      width:1920px;
      height:100%;
      background:$color-white;
    }

    .scroll_progress {
      opacity:1;
    }

    .board_info {
      .board_title {
        font-size:40px;
        line-height:50px;

        sup {
          font-size:10px;
          margin-left:3px;
        }
      }

      .board_desc {display:none;}

      .menu {
        right:auto;
        left:50%;
        margin-left:14px;
        transform:scale(.5);
        transform-origin:0 0;
      }
    }
    .board_sort {
      padding:0;
      padding-top:68px;

      ul li {
        font-size:15px;
        line-height:24px;

        & + li {margin-left:10%;}
        & > span {display:none;}
        & > a {
          min-width:120px;
          background-size:7px auto;
          span:after {display:none;}
        }
      }
    }
  }
  .vm & {
    transition:padding .3s;
  }
  .vm.board-open & {
    // padding-top:203px;
    // padding-top:135px;
    padding-top:120px;
    // transition:padding .3s;
  }
  .vm.header_minify.board-up & {
    padding-top:90px;
  }
  .vm.header_minify.board-up.board-open & {
    padding-top:90px;
  }
  .vm.header_minify & {
    z-index:90;
    height:auto;
    padding-top:20px;
    transition:padding .3s;

    .scroll_progress {
      display:block;
      opacity:1;
      width:100%;
      margin:0;
      position:absolute;
      top:auto;
      bottom:0;
      left:0;
    }

    .board_info {
      .menu {
        left:auto;
        right: 25px;
        transform:none;
      }
    }

    .board_title {
      font-size:26px;
      line-height:28px;
    }

    .board_sort {
      padding:25px 0 15px;

      ul li{
        font-size:13px;
        line-height:20px;

        &>a {
          min-width:60px;
        }
      }
    }
  }

  .vm & {
    z-index:90;
    padding-top:125px;
    height:auto;
    background:$color-white;
  }
}


.board_title{
  transform:translateZ(0);
  .vp & {min-height:100px;}
  .header_minify & {min-height:auto;}

  .flip-card {
    position: relative;
    perspective: 1600px;
    transform-style: preserve-3d;
    width:100%;

    [class*=text-item] {
      width:100%;
      display:none;
    }

    .front-panel {
      background:$color-white;
      transition:transform .25s;
      backface-visibility: hidden;
      transform: rotateX(0deg);
    }
    .back-panel {
      background:$color-white;
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      transition:transform .25s;
      backface-visibility: hidden;
      transform: rotateX(-180deg);
      visibility: hidden;
    }
  }
  &.card-view-ing .back-panel {
    visibility: visible;
  }

  &.active1 .text-item1,
  &.active1 .text-item2 {display:block;}
  &.active2 .text-item2,
  &.active2 .text-item3 {display:block;}
  &.active3 .text-item3,
  &.active3 .text-item4 {display:block;}

  &.active4 .text-item4,
  &.active4 .text-item5 {display:block;}
  &.active5 .text-item5,
  &.active5 .text-item6 {display:block;}
  &.active6 .text-item6,
  &.active6 .text-item7 {display:block;}
}


.board_info {
  position:relative;
  max-width:980px;
  margin-left:120px;

  .board_title {
    font-size:86px;
    line-height:100px;
    font-weight:700;
    letter-spacing:-0.01em;

    // .current-title {opacity:0;
    //   .header_minify & {opacity:1;}
    // }

    span {
      -webkit-text-stroke: 1px $color-black;
      -webkit-text-fill-color: $color-white;

      .ie & {
        color: $color-white;
        text-shadow:
          -1px -1px 0 $color-black,
           1px -1px 0 $color-black,
           -1px 1px 0 $color-black,
            1px 1px 0 $color-black;
      }
    }

    sup {
      display:inline-block;
      font-size:22px;
      line-height:1;
      vertical-align:top;
      font-weight:500;
    }

  }

  .board_desc {
    line-height:20px;
    padding-top:20px;
    color:#999999;
    @include font($notoSans, 17px, 400);
  }

  .menu {
    position:absolute;
    top:0;
    right:-40px;
    display:block;
    width:100px;
    height:100px;
    line-height:100px;
    background:$color-black;
    border-radius:50%;
    color:transparent;
    text-align:center;

    i {
      display:inline-block;
      width:14px;
      height:14px;
      border-radius:50%;
      background:$color-white;
      vertical-align:middle;
      transition:margin .2s cubic-bezier(0, 0, 0.2, 1);

      &+i {margin-left:8px;}
    }

    &:hover,
    &:focus {
      i {
        &+i {margin-left:-14px;}
      }
    }

    &.btn_close {
      background:$color-white;
    }
    &.yt {background-color:#fc0101;}
    &.tw {background-color:#00a3ff;}
    &.in {background-color:#ff0075;}
    &.fb {background-color:#1877f2;}
    &.fc {background-color:#833ab4;}
  }

  .vm & {
    padding-right:0;
    margin-left:43px;

    .board_title {
      font-size:44px;
      line-height:48px;
      transition:all .3s;
      .safari & {perspective:10000;}

      span {
        display:block;
      }
      sup {
        font-size:11px;
      }
    }

    .board_desc {
      // display:none;
      // margin-top:5px;
      padding-top: 5px;
      font-size: 12px;
      color: #999999;
    }

    .menu {
      right:25px;
      width:52px;
      height:52px;
      line-height:52px;

      i {
        width:7px;
        height:7px;

        &+i {margin-left:4px;}
      }
    }
  }
}


.board_sort {
  max-width:980px;
  margin-left:120px;
  padding:70px 0 70px;
  @include clearfix;

  li {
    position:relative;
    font-size:23px;
    line-height:33px;
    float:left;
    vertical-align: top;

    & + li {margin-left:16%;}
    & > a {
      position:relative;
      display:block;
      min-width:150px;
      padding-right:10px;
      box-sizing:border-box;

      &:after {
        content:'';
        display:block;
        position:absolute;
        width:0;
        height:0;
        top:50%;
        right:0;
        margin-top:-3px;
        border-top: 6px solid $color-black;
        border-right: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid transparent;
      }

      span {
        display:inline-block;
        position:relative;

        &:after {
          content:'';
          position:absolute;
          left:0;
          bottom:4px;
          display:block;
          height:1px;
          width:100%;
          background:$color-black;
        }
      }
    }
    &.open>a:after {
      margin-top:-10px;
      transform:rotate(180deg);
    }
  }
  .dropdown_box {
    display:none;
    z-index:1;
    position:absolute;
    left:0;
    top:75px;
    width:200px;
    background:$color-white;
    border:1px solid $color-black;

    a {
      display:block;
      width:100%;
      height:46px;
      line-height:46px;
      font-size:16px;
      padding-left:20px;
      box-sizing:border-box;

      &+a{border-top:1px solid $color-black;}
      &.on,
      &:hover,
      &:focus {
        background:$color-black;
        color:$color-white;
      }
    }

    .header_minify & {
      top:37px;
      width:160px;
      a {padding-left:15px;}
    }
    .vm & {
      width:100px;
      top:34px;
      a {
        height:30px;
        line-height:30px;
        font-size:12px;
        padding-left:10px;
      }
    }
    .vm.header_minify & {
      top:35px;
    }
  }

  li.gender {
    &>a span{
      color:transparent;
      white-space:nowrap;
      &:after {display:none;}
    }
    i {
      display:inline-block;
      width:26px;
      height:26px;
      margin-right:12px;
      vertical-align: middle;
      background-repeat:no-repeat;
      background-size:100% auto;

      &.all {background-image:url(../images/board/icon_emoticon_all.svg);}
      &.woman {background-image:url(../images/board/icon_emoticon_woman.svg);}
      &.man {background-image:url(../images/board/icon_emoticon_man.svg);}
    }
    .dropdown_box {
      a.on,
      a:hover,
      a:focus {
        i.all {background-image:url(../images/board/icon_emoticon_all_w.svg);}
        i.woman {background-image:url(../images/board/icon_emoticon_woman_w.svg);}
        i.man {background-image:url(../images/board/icon_emoticon_man_w.svg);}
      }
    }

    .header_minify & i {
      width:16px;
      height:16px;
    }
  }

  .vm & {
    // padding:27px 0 55px;
    padding:0;
    padding-top:25px;
    margin-left:43px;

    ul li {
      font-size:17px;
      line-height:25px;

      & + li {margin-left:10%;}
      & > span {display:none;}
      & > a {
        min-width:72px;
        background-size:7px 4px;
        span:after {
          bottom:2px;
          // height:2px;
        }
        &:after {
          border-width:4px;
          margin-top:-2px;
        }
      }
      &.gender > a {
        width:72px;
      }
      &.open>a:after {margin-top:-7px;}
    }
    .gender {
      i {
        width:16px;
        height:16px;
      }
    }
  }
}


.board_container {
  max-width:1100px;
  margin:0 auto;
  @at-root {
    .board_header + & {
      padding-top:440px;

      .header_minify.vp & {
        padding-top:290px;
        transition:padding .3s;
      }
      .header_minify.vm & {
        padding-top:170px;
        transition:padding .3s;
      }

    }
  }
  // padding-bottom:170px;
  padding-bottom:30px;
  background:$color-white;



  &.alone {
    padding:0 !important;
    .board_item {
      // min-height:102px;
      .title {
        min-height:34px;
        line-height:34px;
        strong {
          display:inline-block;
          vertical-align: middle;
        }
      }
    }

    .vm &.list-youtube {
      margin:0 -15px;
      .board_item {
        .total {
          padding-bottom:0;
        }
      }
    }
  }

  .vm & {
    padding-top:230px;
    padding-bottom:30px;
    overflow:hidden;
    transition:all .3s;
 
  }
  .vm.board-open & {
    // padding-top:325px;
    padding-top:220px;
    transition:all .3s;
  }
}

.board_content_info {
  position:relative;
  min-height:17px;
  margin-left:120px;
  border-bottom:1px solid #e5e5e5;
  padding:20px 0 25px;

  .info_sort {
    display:inline-block;

    .date {display:none;}

    li {
      position:relative;
      width:auto;
      display:inline-block;
      margin-right:36px;

      &+li {margin-right:0;}

      a {
        display: block;
        position: relative;
        box-sizing: border-box;
        @include font($notoSans,14px,500);
      }

      & > a {
        display:inline-block;
        width:auto;
        padding-right: 20px;
        line-height:20px;

        &:after {
          position:absolute;
          top:0;
          right:0;
          content:'';
          display:block;
          width:9px;
          height:100%;
          // background: url('../images/board/icon_sort_dropdown.svg') no-repeat 100% 57%;
          content: "";
          display: block;
          position: absolute;
          width: 0;
          height: 0;
          top: 50%;
          right: 0;
          margin-top: -3px;
          border-top: 6px solid #000;
          border-right: 6px solid transparent;
          border-bottom: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
      &.open > a:after {
        transform:rotate(180deg);
      }
    }
    .dropdown_box {
      display:none;
      z-index: 3;
      position: absolute;
      left: 0;
      top: 30px;
      min-width: 100%;
      background: #fff;
      border: 1px solid #000;

      a {
        padding:0 14px;
        height:40px;
        line-height:40px;
        white-space:nowrap;
        width:100%;

        &+a{border-top:1px solid $color-black;}
        &.on,
        &:hover,
        &:focus {
          background:$color-black;
          color:$color-white;
        }
      }
    }
  }

  .info_right {
    float:right;
  }
  .date {
    display:inline-block;
    font-size:13px;
    line-height:20px;
    font-weight:300;
    color:#999;
    vertical-align:middle;
    margin-right:15px;
  }

  a.refresh {
    display:inline-block;
    position:relative;
    width:20px;
    height:20px;
    overflow:hidden;
    color:transparent;
    vertical-align:sub;

    &:after{
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:100%;
      transform:rotate(0deg);
      transform-origin:50%;
      background:url(../images/board/icon_refresh.svg) no-repeat 50% 50%;
      transition:transform .3s ease-in-out;
    }

    &:hover {
      &:after {
        transform:rotate(-360deg);
      }
    }
  }

  .vm & {
    margin-left:43px;
    padding:11px 0;
    padding-right:15px;

    .info_sort {
      .date {
        display:block;
        width:100%;
        margin:0;
        font-size:10px;
        line-height:17px;
        margin-top:5px;
      }
      li {
        margin-right:20px;
        a {font-size:12px;}
        & > a{
          padding-right:15px;
          line-height:22px;
          &:after {
            border-width: 4px;
            margin-top: -2px;
          }
        }
      }
      .dropdown_box {
        a {
          height:30px;
          line-height:30px;
          padding:0 10px;
        }
      }
    }

    .info_right {
      width:100%;
    }

    .date {
      display: none;

    }
    a.refresh {
      position:absolute;
      bottom:11px;
      right:15px;
      width:17px;
      height:17px;
    }
  }
}

.board_paging {
  width:100%;
  text-align:center;
  font-size:0;
  margin:20px 0;
  padding-left:120px;
  box-sizing:border-box;

  a {
    display:inline-block;
    width:38px;
    height:38px;
    border-radius:50%;
    font-size:16px;
    line-height:39px;
    border:2px solid $color-black;
    background-color:$color-white;
    box-sizing:border-box;
    color:$color-black;
    margin:5px 15px;

    &.on,
    &:hover,
    &:focus {
      color:$color-white;
      background-color:$color-black;
    }
  }

  .vm & {
    padding-left:0;
    a {
      width:30px;
      height:30px;
      line-height:31px;
      font-size:12px;
      border-width:1px;
      margin:5px 12px;
    }
  }
}
.ko .board_content_head .title,
.ko .board_content_head .views,
.ko .board_content_head .total,
.ko .board_content_head .variation,
.ko .board_content_head .release,
.ko .board_content_head .link,
.ko .board_content_head .peak {
  font-weight: 500 !important;
  font-family: "Noto Sans KR", sans-serif;
}
.ko .board_sort a span {
  font-size:21px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.ko .board_sort .dropdown_box a span{
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.header_minify .ko .board_sort a span {
  font-size:15px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.header_minify .ko .board_sort .dropdown_box a span{
  font-size: 15px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.vm .ko .board_sort a span {
  font-size:15px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.vm.header_minify .ko .board_header .board_sort a span {
  font-size:13px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.vm.header_minify .board_title span {
  display: initial;
}
.vm .ko .board_sort .dropdown_box a span{
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}
.vm.header_minify .ko .board_sort .dropdown_box a span{
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}


.board_content_head,
.board_content_cont .board_item {
  position:relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  & > div {
    flex-grow: 1;
    flex-basis: 0;
    text-align:center;
    box-sizing: border-box;
  }
  .ranking {
    flex-basis:120px;
    flex-grow:0;
    text-align: left;
    min-width:120px;
  }
  .new ,.ranking.freeze{
    color:#ff354e;
  }
  .active .new, .active .ranking.freeze{
    color:#ffffff;
  }
  .title {
    flex-basis: 41%;
    text-align: left;
  }


  .vp .list-youtube & {
    .views,
    .total,
    .peak,
    .release {
      margin-left:55px;
      flex-grow:0;
      min-width:100px;
    }
    .total {margin-left:0;}
    .peak {white-space:nowrap;font-weight:300;}
    .release {font-weight:300;margin-right: 40px;}
    .title {width: calc(41% - 40px);}
    .clickable {display:none;}
  }

  

  .vp .list-instagram & {
    .total,
    .variation,
    .link {
      flex-basis:auto;
      flex-grow:0;
    }
    .total {
      min-width:110px;
    }
    .variation {
      min-width:80px;
      margin-left:35px;
    }
    .link {
      min-width:90px;
      margin-left:70px;
      // margin-right:-10px;
    }
  }

  .vm & {
    .total {text-align: left;}
  }
}

.board_content_head {
  padding:35px 0;

  & > div {
    text-transform: uppercase;
    font-size:12px;
    letter-spacing:0.05em;
  }

  .title {
    padding-left:20px;

    .list-instagram & {padding-left:72px;}
  }

  .list-youtube & {
    .views,
    .total {
      color:$color-main;
    }
  }
}

.flex-cell {
  position:relative;
  z-index:1;

  .vp .list-youtube & {
    height:102px;
  }
  .vp .alone & {
    // min-height:102px;
    height:auto;
  }
}

.board_content_cont {
  span.vari {
    i {
      display:inline-block;
      width:7px;
      height:4px;

      color:transparent;
      vertical-align: middle;
      margin-right:7px;
      background-repeat:no-repeat;
      background-position:0 0;
    }
    &.up i{
      background-image:url(../images/board/icon_rank_up.svg);
    }
    &.down i{
      background-image:url(../images/board/icon_rank_down.svg);
    }
    &.none i{
      width:9px;
      height:2px;
      background:$color-black;
      margin-left:6px;
    }
  }

  .board_item {
    position:relative;
    padding: 25px 0;
    box-sizing:border-box;
    overflow:hidden;

    &:after {
      opacity:0;
      content:'';
      display:block;
      position:absolute;
      z-index:-1;
      top:0;
      left:50%;
      margin-left:-580px;
      width:1540px;
      height:100%;
      background:$color-main;
      pointer-events:none;
      transform:scaleY(0);
      transform-origin:bottom;
    }

    & > div {
      word-break: break-word;
      font-size:13px;
    }

    .ranking {
      position:relative;
      @include font($nimbusSans, 16px, 400);

      span.vari {
        position:absolute;
        left:45px;
        top:50%;
        transform:translateY(-50%);
        @include font($nimbusSans, 12px, 300);
      }
    }

    .title {
      position:relative;
      @include font($notoSans, 14px, 400);
      color:#666;
      // padding-left:106px;
      // overflow:hidden;

      .text-wrap {
        width:calc(100% - 136px);
        overflow:hidden;
        margin-left:auto;
        padding-right:40px;
      }

      strong {
        display:block;
        font-size:17px;
        font-weight:700;
        line-height:29px;
        color:$color-black;
        width:100%;
        max-width:390px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      a.view_yt {
        position:absolute;
        left:0;
        top:50%;
        transform:translateY(-50%);
        figure {
          position:relative;
          width:72px;
          height:42px;
          overflow:hidden;
          transition:transform .4s cubic-bezier(0.22, 0.61, 0.36, 1);
        }

        img {
          display:block;
          position:absolute;
          top:50%;
          left:50%;
          width:100%;
          transform:translate(-50%,-50%);
          transition:transform .3s;
        }

        &:after{
          content:'';
          z-index:1;
          display:block;
          position:absolute;
          top:0;
          left:0;
          right:0;
          bottom:0;
          width:100%;
          height:100%;
          background:$color-black;
          opacity:0;
          transition:opacity .3s, transform .4s cubic-bezier(0.22, 0.61, 0.36, 1);
        }
        &:before{
          content:'';
          z-index:2;
          display:block;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background: url(../images/board/icon_view_yt.svg) no-repeat 50%/20px 27px;
          opacity:0;
        }
      }

      .list-instagram & {
        min-height:40px;
        padding-left:72px;
        line-height:36px;

        figure {
          position:absolute;
          left:0;
          top:50%;
          width:40px;
          height:40px;
          border-radius:50%;
          overflow:hidden;
          transform:translateY(-50%);

          img {
            display:block;
            position:absolute;
            top:50%;
            left:50%;
            max-width:40px;
            transform:translate(-50%,-50%);
          }
        }

        a.view_sg {
          position:relative;
          vertical-align:middle;

          &:after {
            content:'';
            display:block;
            position:absolute;
            left: 0;
            bottom: 2px;
            width: 100%;
            height: 1px;
            background: $color-black;
          }
        }
      }
    }

    p {
      position:relative;
      display:inline-block;
    }

 
  }

  .chart_area {
    position: relative;
    top: 0;
    left:0;
    width:100%;
    height:auto;
    max-height:0;
    overflow:hidden;
    transition:max-height .3s;

    .inner {
      opacity:0;
      position:relative;
      overflow:hidden;
      height:auto;
      transition:opacity .1s;
      // pointer-events:none;
    }

    .chart {
      img {display:block;}
      .pc {display:block;
        .vm & {display:none;}
      }
      .mob {display:none;
        .vm & {display:block;max-width:375px;margin:0 auto;}
      }
    }

    &:after {
      content:'';
      display:block;
      position:absolute;
      z-index:-1;
      top:0;
      left:50%;
      margin-left:-580px;
      width:1540px;
      height:100%;
      background:$color-main;
    }

    .btn_chart_close {
      position:absolute;
      left:50%;
      bottom:22px;
      width:42px;
      height:42px;
      margin-left:-21px;
      border-radius:50%;
      color:transparent;
      overflow:hidden;
      background:$color-white url(../images/board/icon_chart_close.svg) no-repeat 50% 50%;
      pointer-events:all;
    }

    

    .period_box {
      position:absolute;
      left:auto;
      right:10px;
      top:50%;
      transform:translateY(-50%);

      a {
        display:block;
        font-size:15px;
        width:38px;
        height:38px;
        line-height:40px;
        text-align:center;
        color:$color-white;
        border-radius:50%;
        border:2px solid transparent;
        pointer-events: all;

        &+a {margin:0;margin-top:21px;}
        &:hover,
        &.on {border-color:$color-white;transition:border .3s;}
      }
    }
  }

  .list-youtube & {

    a.view_sg {
      position:relative;
      display:inline-block;
      height:24px;
      line-height:22px;
      margin-right: 10px;
      padding:0 0 0 10px;
      box-sizing:border-box;
      color:#666;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      max-width: 300px;

      span {
        margin-left:-10px;
        transition:margin .3s;
      }

      &:after {
        opacity:0;
        content:'';
        position:absolute;
        top:0;
        right:0;
        width:100%;
        height:100%;
        box-sizing:border-box;
        display:block;
        border-radius:14px;
        border:1px solid $color-white;
        background:url(../images/board/icon_arrow_right.svg) no-repeat;
        background-size:11px 100%;
        background-position:calc(100% - 9px) 50%;
      }
    }

    .preview_area {
      position:absolute;
      z-index:-2;
      top:0;
      left:50%;
      margin-left:-580px;
      width: 1540px;
      // height:102px;
      height:100%;
      background:$color-black;
      pointer-events:none;
      overflow:hidden;
      opacity:0;
      transition:height .5s, opacity .5s;

      img {
        display:block;
        position:absolute;
        top:50%;
        left:0;
        width:100%;
        transform:translateY(-50%);
        opacity:0;
        transition:opacity .5s;
      }
    }
    a.btn_chart {
      opacity: 1;
      -webkit-transition: opacity .3s;
      transition: opacity .3s;
      opacity:1;
      border: 1px solid black;
      z-index:1;
      position:absolute;
      top:50%;
      // left:-38px;
      right:10px;
      display:block;
      width:28px;
      height:28px;
      border-radius:50%;
      background:$color-white;
      overflow:hidden;
      color:transparent;
      transform:translateY(-50%);

      i{
        display:block;
        left:50%;
        bottom:9px;
        height:4px;
        transform:translateX(-50%);

        &,
        &:after,
        &:before {
          position:absolute;
          display:block;
          width:1px;
          // background:$color-main;
          background:#000000
        }
        &:after,
        &:before {
          content:'';
          bottom:0;
        }
        &:after {height:12px;margin-left:-4px;}
        &:before {height:7px;margin-left:4px;}
      }
    }
  }

  .list-instagram & {
    a.btn_chart {
      opacity: 1;
      -webkit-transition: opacity .3s;
      transition: opacity .3s;
      opacity:1;
      border: 1px solid black;
      z-index:1;
      position:absolute;
      top:50%;
      // left:-38px;
      right:75px;
      display:block;
      width:28px;
      height:28px;
      border-radius:50%;
      background:$color-white;
      overflow:hidden;
      color:transparent;
      transform:translateY(-50%);

      i{
        display:block;
        left:50%;
        bottom:9px;
        height:4px;
        transform:translateX(-50%);

        &,
        &:after,
        &:before {
          position:absolute;
          display:block;
          width:1px;
          // background:$color-main;
          background:#000000
        }
        &:after,
        &:before {
          content:'';
          bottom:0;
        }
        &:after {height:12px;margin-left:-4px;}
        &:before {height:7px;margin-left:4px;}
      }
    }
    a.view_sg {
      display:inline-block;
      width:auto;
    }
    .btn_link {
      display:inline-block;
      width:30px;
      height:30px;
      color:transparent;
      overflow:hidden;
      background-size: 100%;
      background-repeat: no-repeat;
      background-image:url(../images/board/kr-chart-link-nor.svg);
    }
  }
}
.vmgnb.vp {
  .board_info .menu {right:0;}
  .board_content_cont .board_item .ranking {
    padding-left:10px;
    span {
      left:48px;
    }
  }
}
.vp .board_content_cont .active {
  height:auto;

  .board_item {
    color:$color-white;
    overflow:visible;

    &:after {
      opacity:1;
      // transition:opacity .2s;
      transform:scaleY(1);
      transition:transform .2s ease-out;
    }
    .ranking.freeze{
      color:#fff
    }
    .new{
      color:#fff
    }
    .title {
      a.view_sg {
        color:$color-white;
        padding:0 30px 0 10px;
        span {margin-left:0;}

        &:after {
          opacity:1;
          transition:opacity .3s .05s;
        }
      }

      a.view_yt {
        figure {
          transform:scale(1.4);
          // transition:transform .4s cubic-bezier(0.22, 0.61, 0.36, 1);
        }
        &:after {
          transform:scale(1.4);
          opacity:.3;
          // transition:opacity .3s, transform .4s cubic-bezier(0.22, 0.61, 0.36, 1);
          // transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
        }
        &:before {
          opacity:1;
          transition:opacity .3s;
        }
      }
    }

    .title,
    .title strong {
      color:$color-white;
    }
 
    a.btn_chart {
      // position: absolute;
      opacity:1;
      border: 1px solid #fff;
      transition:opacity .3s;
      i {
        background:#ff0000;
        animation: 1s ease 1 equalizer2;
        &:after {
          background:#ff0000;
          animation: 1s ease 1 equalizer1;
        }
        &:before {
          background:#ff0000;
          animation: 1s ease 1 equalizer3;
        }
      }
      &:hover {
        i {
          animation: 1s ease infinite equalizer2;
          &:after {
            animation: 1s ease infinite equalizer1;
          }
          &:before {
            animation: 1s ease infinite equalizer3;
          }
        }
      }
    }

    span.vari.up i {
      background-image: url(../images/board/icon_rank_up_w.svg);
    }
    span.vari.down i {
      background-image: url(../images/board/icon_rank_down_w.svg);
    }
    span.vari.none i {
      background:$color-white;
    }

    .btn_link {background-image:url(../images/board/kr-chart-link-hover.svg);}
  }

  .chart_area {
    overflow:visible;
  }

  &.chart_on .chart_area{
    display:block;
    height:auto;
    max-height:800px;
    transform:scaleY(1);

    .inner {pointer-events:auto;opacity:1;transition:opacity .3s .3s;}
    .chart img {display:block;}
  }
}

.vp .board_content_cont .topfive {
  height:auto;
  min-height:102px;
  transition:max-height .4s, height .4s, min-height .4s;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);

  &.active {
    // height:320px;
    min-height:320px;
    max-height:320px;

    .board_item:after {
      // display:none;
      opacity:0;
      transition:opacity .2s .3s;
    }

    .preview_area {
      // height:100%;
      opacity:1;
      img.on {
        opacity:1;
      }
    }
  }
  &.active.chart_on {
    height:auto;
    max-height:800px;
    transition:max-height .3s, height .3s, min-height .3s;

    .board_item:after {
      // display:block;
      opacity:1;
      transition:opacity 0s;
    }

    .preview_area {
      // height:102px;
      opacity:0;
      img {
        opacity:0;
        transition:none;
      }
    }
  }
}

.vp .list-instagram .board_content_cont .active {
  .board_item .title a.view_sg:after {background:$color-white;}
}

.vm .board_content_head {
  padding:20px 0 15px;

  .ranking {
    min-width:auto;
    max-width:43px;
  }
}

.vm .list-youtube .board_content_head {
  .views,
  .peak,
  .release {
    display:none;
  }
  .total {
    flex-basis: 76px;
    padding-left:30px;
    padding-right:0;
  }
}
.vm .list-instagram .board_content_head {
  .link {
    display:none;
  }
  .title {
    flex-basis:30%;
    padding-left: 60px;
  }
  .total {
    padding-left:18px;
    flex-basis:96px;
    flex-grow: 0;

    span {display:none;}
  }
  .variation {
    flex-basis:80px;
    flex-grow: 0;
    padding-right:45px;
  }
}

.vm .board_content_head {
  & > div {
    font-size:10px;
  }
  .title {
    padding-left:16px;
  }
}

.vm .board_content_cont {
  .flex-area {
    width:100%;
    overflow:hidden;
  }

  span.vari {
    i {
      width:5px;
      height:3px;
      background-size:5px auto;
      margin-right:5px;
    }
    &.none i {
      width:6px;
      height:1px;
    }
  }
  .chart_on span.vari.up i {
    background-image: url(../images/board/icon_rank_up_w.svg);
  }
  .chart_on span.vari.down i {
    background-image: url(../images/board/icon_rank_down_w.svg);
  }
  .chart_on span.vari.none i {
    background:$color-white;
  }

  .board_item {
    padding:19px 0;

    & > div {
      font-size:10px;
    }

    .ranking {
      min-width:auto;
      text-align:center;
      font-size:13px;
      line-height:16px;
      flex-basis:43px;
      flex-grow:0;

      span {
        position:static;
        display:inline-block;
        width:100%;
        font-size:10px;
        line-height:11px;
        margin-top:8px;
      }
    }

    .title {
      padding-left:73px;
      overflow:hidden;

      a.view_yt {
        figure {
          width:60px;
          height:34px;
        }
        &:before {
          opacity:1;
          background-size:13px auto;
        }
        &:after {
          opacity:.2;
        }
      }

      strong {
        font-size:13px;
        line-height:20px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .text-wrap {
        width:100%;
        padding-right:0;
      }
    }

    a.btn_chart {
      display: none;
      opacity:1;
      left:-21px;
      width:17px;
      height:17px;
      margin-top:-2px;
      background-image:url('../images/board/ico_graph_mo1.svg');
      background-size:100%;

      i {
        display:none;
      }
    }
  }

  .detail_area {
    box-sizing:border-box;
    background:$color-main;
    color:$color-white;
    height:auto;
    max-height:0;
    overflow:hidden;
    transition:max-height .2s;


    & > div {
      display: flex;
      flex-direction: row;
      align-items: center;
      height:23px;
      margin-left:43px;
      margin-right:34px;

      &.views {padding-top:17px;}
      &.peak {font-weight:300;}
      &.release {padding-bottom:17px;font-weight:300;}

      strong {
        font-size:10px;
        flex-grow:1;
        flex-basis: 41%;
      }
      span {
        flex-basis: 140px;
        flex-grow:1;
        font-size:11px;
        text-align: right;
      }
    }
  }

  .chart_area {
    overflow:hidden;
    left: 0;
    right:0;
    width: 100%;
    height:auto;
    max-height:0;
    margin:0;
    z-index:2;

    img {width:100%;}

    &:after {
      left:0;
      right:0;
      width:100%;
      margin:0;
    }

    .chart {
      // padding-bottom:82px;
      // padding-top: 30px;
      padding-bottom: 25px;
    }

    .period_box {
      position:static;
      transform:none;
      width:100%;
      text-align: right;
      padding:20px 13px;
      padding-bottom:20px;
      box-sizing:border-box;

      a {
        display:inline-block;
        width:32px;
        height:32px;
        line-height:34px;
        font-size:14px;
        margin-top:0;
        border-width:2px;
        font-weight:700;

        &+a {margin-left:11px;}
      }
    }
  }
}
.chart_on.active .btn_chart{
  display: none !important;
}

.list-youtube .chart_on.active .btn_chart2{
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 28px;
  height: 28px;
  margin-top: -2px;
  margin-right: 12px;
  background-image: url(../images/board/kr-chart-btn-colse.svg)!important;
  background-size: 100%;
}
.ie .list-youtube .chart_on.active .btn_chart2{
  margin-top: -13px;
}
.list-instagram .chart_on.active .btn_chart2{
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 28px;
  height: 28px;
  margin-top: -2px;
  margin-right: 76px;
  background-image: url(../images/board/kr-chart-btn-colse.svg)!important;
  background-size: 100%;
}


.vm .list-youtube .board_content_cont {
  .views {
    font-weight:500;
  }
  .board_item {
    .clickable {
      z-index:1;
      display:block;
      position:absolute;
      top:0;
      right:0;
      width:30px;
      height:100%;
      border:0;
      padding:0;
      background:transparent;

      &:after {
        content:'';
        display:block;
        position:absolute;
        width:0;
        height:0;
        top:50%;
        right:13px;
        margin-top:-9px;
        border-top: 4px solid $color-black;
        border-right: 4px solid transparent;
        border-bottom: 4px solid transparent;
        border-left: 4px solid transparent;
      }
    }
    .btn_chart2{
      z-index: 1;
      display: block;
      position: absolute;
      right: 0;
      width: 30px;
      height: 100%;
      width: 20px;
      height: 20px;
      margin-top: -8px;
      margin-right: 10px;
      background-image: url(../images/board/ico_graph_mo1.svg);
      background-size: 100%;
    }
    

    .views,
    .peak,
    .release {
      display:none;
    }

    .total {
      flex-basis: 110px;
      font-size:11px;
      padding-left:25px;
      padding-right:25px;
      padding-bottom:14px;
    }

    a.view_sg {
      font-size:12px;
      padding:0;
      height:auto;
      line-height:17px;
      margin-right: 10px;
      // margin:0;
      transition:none;

      span {margin:0;}

      &:after {
        opacity:1;
        top:auto;
        right:auto;
        left:0;
        bottom:0;
        width:100%;
        height:1px;
        background:#666;
        border:0;
      }
    }
  }

  .active .board_item .clickable:after {
    transform: rotate(180deg);
    margin-top: -14px;
    border-top-color:$color-main;
  }
  .active.chart_on .board_item .clickable:after {
    border-top-color:$color-white;
  }
}
.vm .list-instagram .board_content_cont {
  .board_item {
    .title {
      padding-left:60px;
      flex-basis:30%;
      height:46px;
      line-height:46px;
      width:auto;

      strong {
        max-width:inherit;
        width:100%;
      }

      figure {
        width:46px;
        height:46px;
        img{max-width:46px;}
      }
      a.view_sg {
        display:inline-block;
        vertical-align:middle;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width:auto;
        max-width:100%;
      }
    }
    .total {
      padding-left:30px;
      flex-basis:96px;
      flex-grow: 0;
    }
    .variation {
      flex-basis:110px;
      flex-grow: 0;
      padding-right:25px;
    }
    .link {display:none;}
    .btn_chart2{
      z-index: 1;
      display: block;
      position: absolute;
      right: 0;
      width: 30px;
      height: 100%;
      width: 20px;
      height: 20px;
      margin-top: -2px;
      margin-right: 8px;
      background-image: url(../images/board/ico_graph_mo1.svg);
      background-size: 100%;
    }
  }
}
.vm.header_minify.board-up .menu{
  width: 32px;
  height: 32px;
}

.vm.header_minify.board-up .board_info .menu i {
  top: -10px;
  width: 5px;
  height: 5px;
  position: relative;
}

.vm .board_content_cont .flex-cell {
  height:auto;
  transition:max-height .2s;
}
.vm .list-youtube .board_content_cont .active {
  height:auto;
  max-height:300px;
}
.vm .board_content_cont .active {

  .detail_area {
    height:auto;
    max-height:200px;
  }

  &.chart_on {
    height:auto;
    max-height:600px;

    span.vari i {
      background-position:0 100%;
    }
    span.vari.none i {
      background:$color-white;
    }

    .board_item {
      color:$color-white;

      &:after {
        transform:scaleY(1);
        opacity:1;
        transition:opacity .2s;
      }

      .title {
        a.view_sg {
          color:$color-white;
          &:after{
            background:$color-white;
          }
        }
      }

      .title,
      .title strong {
        color:$color-white;
      }

      a.btn_chart {
        // background-image:url('../images/board/ico_graph_mo2.svg');
        display: none;
      }
    }

    .chart_area {
      height:auto;
      max-height:490px;

      .inner {opacity:1;transition:opacity .3s .2s;}
    }
    .detail_area {
      height:80px;
      border-bottom: 1px solid rgba(255, 255, 255, .2);
      // max-height:0;
    }
  }

}
.detail_area{
  display: none;
}
.vm .detail_area{
  display: block;
}
.vm .chart_on.active .btn_chart2{
  background-image: url(../images/board/kr-chart-btn-colse.svg) !important;
}
.vm .btn_chart_close{
  display: none;
}

.vm .active.chart_on .no_artist_board{
  color:#ffffff
}
.vm .active .no_artist_board{
  color: #666
}
.active .no_artist_board{
  color: #ffffff
}
.vm .no_artist_board{
  font-size:12px;
  height: 20px;
}
.no_artist_board{
  font-size:14px;
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 22px;
  margin-right: 10px;
  padding: 0 0px 0 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}
.vm .board_info .menu{
  top:-4px;
}
.tab_info{
  background-image: url('/@resources/images/artist/kr-chartdetail-btn-info2.svg');
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 8px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}

.vm .tab_info{
  width: 12px;
  height: 14px;
  margin-right: 4px;
}



#ui-datepicker-div { display: none; }


.v3 .board_title .flip-card {
  width: 60%;
}
.v3 .board_container{
  padding-top: 540px;
}

.list-instagram .board_content_cont .active .board_item .title a.view_sg{ 
  padding: 0px;
} 




.list-youtube .board_content_cont .active .board_item .title a.view_yt img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 4s;
  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 4s;
  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}
      @keyframes cf4FadeInOut {
0% {
  opacity:1;
}
17% {
  opacity:1;
}
25% {
  opacity:0;
}
92% {
  opacity:0;
}
100% {
  opacity:1;
}
}
.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(1) {
animation-delay: 3s;
}
.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(2) {
animation-delay: 2s;
}
.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(3) {
animation-delay: 1s;
}
.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(4) {
animation-delay: 0;
}
    

.hasNotice #header{
  top:55px!important;
}
.hasNotice .board_header{
  top:55px!important;
}
.hasNotice .scroll_progress{
  top:232px!important;
}
.hasNotice .board_container{
  padding-top: 465px!important;
}
.v3.hasNotice .board_container {
  padding-top: 545px!important;
}
.hasNotice .section.chart .board_container{
  padding-top: 0px!important;
}
.v3.hasNotice .section.chart .board_container {
  padding-top: 0px!important;
}
.vm.hasNotice .scroll_progress{
  top:177px!important;
}
.vm.hasNotice .board_container{
  padding-top: 285px!important;
}
.vm.hasNotice .section.chart .board_container{
  padding-top: 0px!important;
}