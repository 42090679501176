.vm.none-touch {
  pointer-events:none;
}
body {
  position:relative;
  @include font($nimbusSans, 14px, 500);
  max-width: $max-width;
  margin:0 auto;
  overflow-x:hidden;

  &.preload * {transition: none !important;}
}
a {
  text-decoration: none;
  color: $color-black;
}


#container {
  max-width:$max-width;
  margin:0 auto;
}

.contents {
  position:relative;

  .vp & {margin-top:135px;}
  .vp &.artist-con {margin-top:0;}
  .vmgnb.vm & {margin:0;padding-top:72px;}
}

#header {
  z-index:100;
  position:fixed;
  margin:0 auto;
  top:0;
  left:0;
  right:0;
  width:100%;
  max-width:$max-width;
  padding:35px 25px 10px 60px;
  box-sizing: border-box;
  pointer-events: none;
  @include clearfix;
  .safari & {perspective:10000;}
  .vm & {
    position:absolute;
    z-index:100;
    &.up {
      position:fixed;
      top:0;
      transition:top .3s;
    }
    &.down {
      position:fixed;
      top:-72px;
      transition:top .3s;
    }
    &.ready {
      position:absolute !important;
      top:0px;
    }
    &.ready.down {
      top:-72px !important;
    }
  }
  .vm.board-open & {
    &.down {
      top:-135px;
    }
    &.ready.down {
      top:-135px !important;
    }
  }

  .logo {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion.gif');
        background-size: 100% !important;
      }
    }
  }

  .logo1 {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo_1.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion_1.gif');
        background-size: 100% !important;
      }
    }
  }

  .logo2 {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo_2.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion_2.gif');
        background-size: 100% !important;
      }
    }
  }


  .logo3 {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo_3.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion_3.gif');
        background-size: 100% !important;
      }
    }
  }


  .logo4 {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo_4.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion_4.gif');
        background-size: 100% !important;
      }
    }
  }


  .logo5 {
    float:left;
    width:160px;
    height:108px;
    pointer-events: auto;
    margin-top:-7px;
    margin-left:-7px;
    padding:6px;
    box-sizing: border-box;
    a {
      display:block;
      height:100%;
      color:transparent;
      background:url('../images/logo_5.svg') no-repeat 50%/147px auto;
      background-size:auto;
    }
    &.move{
      padding:0 !important;
      a {
        background-image:url('../images/logo_motion_5.gif');
        background-size: 100% !important;
      }
    }
  }


  .hamburger {
    display:none;
    pointer-events: auto;
  }

  nav {
    float:right;

    ul {
      display:block;
      @include clearfix;
    }
    li {
      float:left;
      a {
        position:relative;
        display:block;
        font-size:17px;
        color:$color-black;
        padding:24px 32px 30px 35px;
        box-sizing:border-box;
        pointer-events: auto;
        font-weight:500;
        text-transform: uppercase;
        &:hover {
          text-decoration:underline;
        }

        .badge {
          display:block;
          position:absolute;
          top:-5px;
          left:50%;
          margin-left:14px;
          color:$color-white;
          font-size:11px;
          line-height:20px;
          height:21px;
          background:$color-black;
          padding:0 8px;
          border-radius:10px;
          font-weight:500;
          text-transform:lowercase;
        }

        .safari & {perspective:10000;}
      }
    }
  }

  &.type-white {
    .logo a {
      background-image:url('../images/logo_white.svg');
    }
    .logo.move a {
      background-image:url('../images/logo_white_motion.gif');
    }
    nav ul li a {
      color:$color-white;

      .badge {
        background:$color-white;
        color:$color-black;
      }
    }
  }

  &.page-detail {
    & ~ #container {
      .contents{margin-top:0}
    }
    .header_minify:not(.vmgnb) & {
      h1.logo {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo.svg') !important;}
      }
      h1.logo1 {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo_1.svg') !important;}
      }
      h1.logo2 {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo_2.svg') !important;}
      }
      h1.logo3 {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo_3.svg') !important;}
      }
      h1.logo4 {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo_4.svg') !important;}
      }
      h1.logo5 {
        width: 147px !important; height: 95px !important;
        a{background-image:url('../images/logo_5.svg') !important;}
      }
    }
    .vmgnb & {
      h1.logo {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
      h1.logo1 {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
      h1.logo2 {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
      h1.logo3 {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
      h1.logo4 {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
      h1.logo5 {
        width: 164px;
        height: 112px;
        margin-top: -8px;
        margin-left: -8px;
      }
    }
    .vmgnb.vm & {
      h1.logo {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
      h1.logo1 {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
      h1.logo2 {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
      h1.logo3 {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
      h1.logo4 {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
      h1.logo5 {
        width: 77px;
        height: 52px;
        margin-top: -4px;
        margin-left: -4px;
      }
    }
  }
}
.vp:not(.vmgnb) #header {
  nav {
    display:block !important;
  }
}

.header_minify #header:not(.page-board) {
  background-color:$color-white;

  &.type-white {background-color:$color-black;}
}

.header_minify:not(.vmgnb) #header {
  .logo {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  .logo1 {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  .logo2 {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  .logo3 {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  .logo4 {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  .logo5 {
    width:100px;
    height:70px;
    margin-top:-5px;
    margin-left:-5px;
  }
  &:not(.page-board) {
    height:80px;
    padding-top:20px;

    .logo {
      width:140px;
      height:40px;
      margin:0;
      a {
        background-image:url('../images/logo_mini.svg');
      }
    }
    &.type-white .logo a {background-image:url('../images/logo_white_mini.svg');}
  }
  &.page-board {
    nav li a {
      font-size:15px;
    }
  }

  nav li a {
    padding-top: 15px;
    padding-bottom:15px;

    .badge {
      width:7px;
      height:7px;
      border-radius:50%;
      color:transparent;
      padding:0;
      margin-left:22px;
      top:4px;
    }
  }
}
.header_minify.vmgnb #header {
  .logo {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .logo1 {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .logo2 {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .logo3 {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .logo4 {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .logo5 {
    width:77px;
    height:52px;
    margin-top:-4px;
    margin-left:-4px;
    padding:0;
  }
  .hamburger {top:22px;}
}

// .vmgnb.header_minify #header:after,
.vmgnb .preload #header:after,
.vmgnb .preload #header ul {display:none;}
.vmgnb #header {
  padding:30px 15px 30px 32px;

  // &:after {
  //   opacity:0;
  //   content:'';
  //   display:block;
  //   z-index:-1;
  //   position:absolute;
  //   top:0;
  //   left:0;
  //   right:0;
  //   width:100%;
  //   height:200px;
  //   background:$color-white;
  //   transition:opacity .2s;
  // }

  &.type-white:after {
    background:$color-black;
    border-bottom-color:$color-white;
  }

  .logo {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }
  .logo1 {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }
  .logo2 {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }
  .logo3 {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }
  .logo4 {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }
  .logo5 {
    width:134px;
    height:92px;
    margin-top:-8px;
    margin-left:-8px;
    padding:6px;
  }

  nav {
    // display:none;
    position: relative;
    // left:70px;
    // top:133px;
    float: right;
    ul {
      li {
        opacity:0;
        transform:translateX(-15px);
        transition:transform .5s, opacity .3s .1s;

        $elements: 4;
        @for $i from 0 to $elements {
          &:nth-child(#{$i + 1}) {
            transition-delay: #{$i*0.1}s;
          }
        }

        a {
          padding:25px 10px;
          // font-weight:700;
          .badge {top:-2px;}
        }
      }
    }
  }

  .hamburger {
    display:block;
    position:absolute;
    margin-top:0;
    top:30px;
    right:30px;
    width:60px;
    height:60px;
    overflow:hidden;

    span {
      color:transparent;

      &:before,
      &:after {
        content:'';
        display:block;
        position:absolute;
        top:50%;
        left:50%;
        width:40px;
        height:6px;
        background:$color-black;
        transform:translateX(-50%);
        transition: all .2s;
      }
      &:before {
        margin-top:-12px;
      }
      &:after {
        margin-top:6px;
      }
    }
  }

  &.type-white .hamburger {
    span:before,
    span:after {
      background:$color-white;
    }
  }

  &.open { //mobild gnb open
    z-index:120;

    &:after {
      opacity:1;
    }

    nav ul li {
      opacity:1;
      transform:translateX(0);
    }

    .hamburger span {
      &:before {
        margin:0 !important;
        transform: rotate(45deg);
        top: 26px;
        left: 10px;
      }
      &:after {
        margin:0 !important;
        transform: rotate(-45deg);
        top: 26px;
        left: 10px;
      }
    }
  }
  &.close {
    nav ul li {
      opacity:0;
      transform:translateX(15px);
    }
  }
}
.vmgnb.vm #header{
  padding:13px;

  &:after {
    // height:130px;
  }

  .logo {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }
  .logo1 {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }
  .logo2 {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }
  .logo3 {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }
  .logo4 {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }
  .logo5 {
    width:60px;
    height:40px;
    margin:0;
    padding:3px;
    margin-bottom: -40px;
  }

  nav{
    top: 1px;
    // width: 100%;
    // right: -100px;
    ul li {
      // width: 25%;
      // max-width: 53px;
      max-width: 90px;
      margin-right: 12px;
      // &:first-child {max-width:80px;margin-right: 3px;}

      a {
        padding:15px 0;
        font-size:11px;
        text-align:center;

        .badge {
          width: 5px;
          height: 5px;
          border-radius: 50%;
          color: transparent;
          padding: 0;
          margin-left: 15px;
          top: 8px;
        }
      }
    }
  }

  .hamburger {
    width:30px;
    height:30px;
    top:20px;
    right:20px;

    span:before,
    span:after {
      width:16px;
      height:3px;
    }
    span:before {
      margin-top:-6px;
    }
    span:after {
      margin-top:3px;
    }
  }

  &.open {

    .hamburger span {
      &:before {
        top: 14px;
        left: 7px;
      }
      &:after {
        top: 14px;
        left: 7px;
      }
    }
  }
}

#footer {
  z-index:2;
  position:relative;
  width:100%;
  background:$color-black;
  color:$color-white;
  padding:60px;
  padding-top:180px;
  box-sizing: border-box;
  @include clearfix;
  overflow:hidden;

  a{
    display:inline-block;
    color: $color-white;
  }

  .logo {
    display:block;
    position:absolute;
    top:60px;
    left:60px;    
    top: 60px;
    left: 60px;
    width: 80px;
    height: 60px;
    overflow:hidden;
    color:transparent;
    background:url(../images/kr-footer-blip-symbol.png) no-repeat 50%;
    background-size:100%;
  }

  .foot {
    width:100%;
    max-width:1440px;
    margin:0 auto;
  }

  .inner {
    width:100%;
    max-width:1440px;
    margin:0 auto;
  }

  .map_wrap{
    z-index:1;
    position:relative;
    margin-left:auto;
    padding-bottom:320px;
    max-width:800px;
    width:100%;

    li {position:relative;}

    a {
      display:block;
      width:100%;
      font-size:120px;
      line-height:140px;
      font-weight:700;

      span {
        display:inline-block;
        transition:transform .5s ease-in-out;
        white-space:nowrap;
      }
    }
    .fake-btn {
      position:absolute;
      top:0;
      left:0;
      display:block;
      width:100%;
      font-size:120px;
      line-height:140px;
      font-weight:700;
      text-indent:-99999px;
      cursor:pointer;
    }

    .detail_wrap {
      opacity:0;
      z-index:-1;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      transition:opacity .3s;

      p {
        @include font($notoSans, 24px, 700);
        line-height:42px;
        max-width:510px;
        word-break:break-word;
        padding-top:190px;
      }

      figure{
        width:100%;

        img {
          z-index:-1;
          position:absolute;
          top:0;
          left:460px;
        }
      }
    }

    li.on {
      a span {
        -webkit-text-stroke: 1px $color-white;
        -webkit-text-fill-color: $color-black;

        .ie & {
          color: $color-black;
          text-shadow:
            -1px -1px 0 $color-white,
             1px -1px 0 $color-white,
             -1px 1px 0 $color-white,
              1px 1px 0 $color-white;
        }
      }
      &.about {
        .detail_wrap {opacity:1;}
        a span {
          transform:translateX(-100%);
        }
      }
    }
  }

  .mail_text {
    position:relative;
    margin-left:auto;
    margin-bottom: 60px;
    max-width:800px;
    width:100%;
    font-size:30px;
    line-height:38px;

    strong {display:block;}
    a {
      display:inline-block;
      color:#7a7a7a;

      &:hover, &:focus {text-decoration: underline;}
    }
  }

  .sns_wrap {
    position: relative;
    margin-left: auto;
    max-width: 800px;
    width: 100%;
    font-size: 30px;
    line-height: 38px;

    @include clearfix;

    a {
      float:left;
      width:30px;
      height:30px;
      color:transparent;
      overflow:hidden;
      margin-left:0;
      transition:margin .3s;
      background-repeat:no-repeat;
      background-size:100%;
      background-position:50%;

      & + a {margin-left:50px;}
      &.tw {
        background-image:url('../images/icon_sns_twitter.svg');
      }
      &.yt {
        background-image:url('../images/icon_sns_youtube.svg');
      }
      &.in {
        background-image:url('../images/icon_sns_instagram.svg');
      }
    }
  }

  .copyright {
    position:absolute;
    left:60px;
    bottom:60px;
    color:#464646;
    font-size:13px;
    line-height:20px;
  }

}

.vm #footer {
  padding:100px 13px 35px;

  .logo {
    top: 10px;
    left: 24px;
    width: 55px;
    height: 55px;
    
    // background-size:21px auto;
  }

  .foot {
    margin-top:55px;
    margin-left:77px;
    @at-root {
      .v0#{&} {margin-left:58px;}
    }
  }

  .map_wrap{
    max-width:none;
    width:auto;
    left:0;
    margin:0;
    margin-left:77px;
    padding-bottom:0;
    @at-root {
      .v0#{&} {margin-left:58px;}
    }

    a {
      font-size:46px;
      line-height:64px;

      span {
        position:static;
        width:auto;
        white-space:normal;
      }
    }

    .detail_wrap{display:none;}
    .fake-btn {display:none;}
  }

  .mail_text {
    font-size:17px;
    line-height:28px;
    position:static;
    width:auto;
    margin-left:0;

    strong{
      line-height: 15px;
    }
  }

  .sns_wrap {
    position:static;
    width:auto;
    margin-top:-40px;
    margin-left:-6px;

    a {
      background-size:22px auto;
      & + a {
        margin-left:30px;
      }
    }
  }

  .copyright {
    position:static;
    width:auto;
    margin-top:40px;
    font-size:10px;
  }
}

.btn_tap {
  // position:absolute;
  z-index:1;
  position:fixed;
  position:sticky;
  float: right;
  margin-bottom: 60px;
  margin-right:60px;

  right:60px;
  bottom:60px;

  margin-top:-40px;
  margin-left:-40px;
  width:80px;
  height:80px;
  border-radius:50%;
  background:#000000;

  .no-sticky & {margin:0;}

  & > button {
    font-family: itc-avant-garde-gothic-pro;
    position:relative;
    display:inline-block;
    width:100%;
    border: 0;
    padding: 0;
    text-align:center;
    font-size:24px;
    height:80px;
    line-height:80px;
    vertical-align: middle;
    color:$color-white;
    background:transparent;
    border-radius:50%;
    transform: rotate(0) scale(1);
    &:before {
      position:absolute;
      top:50%;
      left:50%;
      width:136px;
      height:136px;
      margin-left:-68px;
      margin-top:-68px;
      border-radius:50%;
      background:#000000;
      transform:scale(0);
      transition:transform .3s;
      content:'';
    }

    span {
      position:relative;
      display:block;
      line-height:1;
      vertical-align: middle;
      z-index:1;
      transform:scale(1);
      transition:transform .2s;
    }
  }

  .over {
    position:absolute;
    top:50%;
    left:50%;
    width:136px;
    height:136px;
    margin-left:-68px;
    margin-top:-68px;
    border-radius:50%;
    transform:scale(0);
    transition:transform .3s cubic-bezier(0.9, 0, 0.1, 1) .1s;
    z-index:2;
    background:#000000;
    & > * {
      opacity:0;
      transition:opacity .8s;
    }

    &:after {
      content:'';
      display:block;
      width:14px;
      height:14px;
      border-radius:50%;
      background:$color-white;
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%,-50%);
    }

    & > a {
      &:hover {
        text-decoration: underline;
      }
    }

    .prev,
    .next {
      position:absolute;
      top:50%;
      left:50%;
      width:30px;
      height:20px;
      line-height:20px;
      margin-top:-10px;
      font-size:14px;
      color:$color-white;
      text-align:center;

      &.disabled {
        pointer-events:none;
        opacity:.3;
      }
    }
    .prev {
      margin-left:-55px;
    }
    .next {
      margin-left:25px;
    }

    .top,
    .bottom {
      position:absolute;
      left:50%;
      margin-left:-10px;
      color:transparent;

      span {
        display:block;
        width:20px;
        height:17px;
        background:url(../images/icon_tap_arrow_up.svg) no-repeat 50%;
        background-size:100%;
      }
    }
    .top {
      top:16px;
    }
    .bottom {
      bottom:16px;
      span {
        background:url(../images/icon_tap_arrow_down.svg) no-repeat 50%;
      }
    }
    .top,
    .bottom {
      &:hover:before {
        position:absolute;
        bottom:-4px;
        left:50%;
        width:10px;
        height:1px;
        margin-left:-5px;
        background-color:$color-white;
        content:'';
      }
    }
  }

  &.active { //mouseenter
    & > button {
      &:before {
        transform: scale(1);
      }
      span {
        transform: scale(0);
      }
    }
    .over {
      transform: scale(1);
      & > * {
        opacity:1;
      }
    }
  }
}

.vm .btn_tap {
  margin-right:13px;
  margin-bottom:13px;
  right:13px;
  bottom:13px;

  .no-sticky & {margin:0;}

  width:40px;
  height:40px;
  margin-left:-20px;
  margin-top:-20px;

  & > button {
    font-size:11px;
    height:40px;
    line-height:40px;
  }

  &.active > button {
    transform: rotate(720deg) scale(0.1);
    transition: transform .4s ease-in-out;
  }

  .over {
    width:100px;
    height:100px;

    .prev,
    .next {
      font-size:11px;
    }
    .prev {margin-left:-43px;}
    .next {margin-left:13px;}

    .top {top:13px;}
    .bottom {bottom:13px}
  }
}


.v2.vp #footer .copyright {
  position: relative;
  margin-left: auto;
  left:0px;
  bottom:0px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}
.v2.vp .spaceoddity {
  position: relative;
  margin-left: auto;
  left:0px;
  bottom: -15px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v3.vp #footer .copyright {
  position: relative;
  margin-left: auto;
  left:0px;
  bottom:0px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}
.v3.vp .spaceoddity {
  position: relative;
  margin-left: auto;
  left:0px;
  bottom: -15px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v3.header_minify #header nav ul li a .badge{
  width: 7px;
  height: 7px;
  border-radius: 50%;
  color: transparent;
  padding: 0;
  margin-left: 22px;
  top: 5px;
}

.v3.vmgnb.header_minify #header nav ul li a .badge{
  top: 13px;
}