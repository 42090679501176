.dot_area {
  z-index:-10;
  position:fixed;
  top:140px;
  left:50%;
  width:$max-width;
  height:100%;
  transform:translateX(-50%);

  .vm & {
    width:100%;
    left:0;
    right:0;
    transform:none;
  }

  [class^=dot_box] {
    position:absolute;
    top:0;
    left:50%;
    width:100%;
    height:100%;
    margin-left:-50%;
    transition:transform .3s ease-out;
    transform:translateZ(-1px);

    &.dot_box2 {transform:translateZ(-2px);}
    &.dot_box3 {transform:translateZ(-3px);}
  }

  span[class^=dot] {
    position:absolute;
    display:block;
    width:11px;
    height:11px;
    border-radius:50%;
    background:$color-white;

    &.dot1 {background:#903262;}
    &.dot2 {background:#eab1b9;}
    &.dot3 {background:#f0aa70;}
    &.dot4 {background:#9ec372;}

    .vm & {
      width:5px;
      height:5px;
    }
  }
}
