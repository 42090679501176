@charset "utf-8";
@import "variable";
@import "mixin";
@import "reset";
@import "webfont";

@import "layout";
@import "board";
@import "dot";

html, body{
  -webkit-tap-highlight-color: rgba(0,0,0,0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}
// html {
//   // overflow-y: scroll;
//   overflow: -moz-scrollbars-vertical;
//   box-sizing: border-box;
//   touch-action: manipulation;
// }

.grid2 line {
  stroke: #000000;
  stroke-opacity: 0.2;
  /* shape-rendering: crispEdges; */
  stroke-width:1px;
}
.grid line {
  stroke: #ffffff;
  stroke-opacity: 0.2;
  /* shape-rendering: crispEdges; */
  stroke-width:1px;
}
.grid path {
  stroke-width: 0;
}
text{
font-family: 'itc-avant-garde-gothic-pro',sans-serif;
}

.page_area{
width: 400px;
height: 50px;
position: relative;
top: 0;
left: 50%;
transform: translateX(-50%);
overflow: hidden;
margin-top: 30px;

}
.page_area  button{
position: absolute;
top: 15px;
background: transparent;
border: 0;
font-size: 14px;
font-weight: bold;
}
.page_area .btnPrev{
 left: 0;
 cursor: pointer;
}
.page_area .btnNext{
 right: 0;
 cursor: pointer;
}
.page_area .board_paging{
position: relative;
top: 0px;
width: 300px;
left: 50%;
transform: translateX(-50%);
margin: 0;
padding-left: 0;
}
.page_area .board_paging .page-count{
display: inline-block;
margin: 5px 10px;
}


.vm .page_area{
width: 200px;
height: 40px;
position: relative;
top: 0;
left: 50%;
transform: translateX(-50%);
overflow: hidden;
margin-top: 30px;
}
.vm .page_area button{
position: absolute;
top: 10px;
background: transparent;
border: 0;
font-size: 14px;
font-weight: bold;
}
.vm .page_area .btnPrev{
 left: 0;
 cursor: pointer;
}
.vm .page_area .btnNext{
 right: 0;
 cursor: pointer;
}
.vm .page_area .board_paging{
position: relative;
top: 0px;
width: 120px;
left: 50%;
transform: translateX(-50%);
margin: 0;
 padding-left: 0;
}
.vm .page_area .board_paging .page-count{
display: inline-block;
margin: 5px 5px;
}

.spinner {
position: fixed;
width:100% !important;
background:white;
height:100% !important;
 /* top: 50%;
left: 50%;
margin-left: -50px; 
margin-top: -50px; 
text-align:center; */
z-index:10000000000;
overflow: auto;
width: 100px; /* width of the spinner gif */
height: 102px; /*hight of the spinner gif +2px to fix IE8 issue */
/* background: url(/@resources/images/loading3.gif) center no-repeat #fff0; */
}

@keyframes lds-ellipsis3 {
0%, 25% {
left: 32px;
-webkit-transform: scale(0);
transform: scale(0);
}
50% {
left: 32px;
-webkit-transform: scale(1);
transform: scale(1);
}
75% {
left: 100px;
}
100% {
left: 168px;
-webkit-transform: scale(1);
transform: scale(1);
}
}
@-webkit-keyframes lds-ellipsis3 {
0%, 25% {
left: 32px;
-webkit-transform: scale(0);
transform: scale(0);
}
50% {
left: 32px;
-webkit-transform: scale(1);
transform: scale(1);
}
75% {
left: 100px;
}
100% {
left: 168px;
-webkit-transform: scale(1);
transform: scale(1);
}
}
@keyframes lds-ellipsis2 {
0% {
-webkit-transform: scale(1);
transform: scale(1);
}
25%, 100% {
-webkit-transform: scale(0);
transform: scale(0);
}
}
@-webkit-keyframes lds-ellipsis2 {
0% {
-webkit-transform: scale(1);
transform: scale(1);
}
25%, 100% {
-webkit-transform: scale(0);
transform: scale(0);
}
}
@keyframes lds-ellipsis {
0% {
left: 32px;
-webkit-transform: scale(0);
transform: scale(0);
}
25% {
left: 32px;
-webkit-transform: scale(1);
transform: scale(1);
}
50% {
left: 100px;
}
75% {
left: 168px;
-webkit-transform: scale(1);
transform: scale(1);
}
100% {
left: 168px;
-webkit-transform: scale(0);
transform: scale(0);
}
}
@-webkit-keyframes lds-ellipsis {
0% {
left: 32px;
-webkit-transform: scale(0);
transform: scale(0);
}
25% {
left: 32px;
-webkit-transform: scale(1);
transform: scale(1);
}
50% {
left: 100px;
}
75% {
left: 168px;
-webkit-transform: scale(1);
transform: scale(1);
}
100% {
left: 168px;
-webkit-transform: scale(0);
transform: scale(0);
}
}
.lds-ellipsis {
/* position: relative; */
top: 45%;
position: absolute;
left: 45%;
}
.lds-ellipsis > div {
position: absolute;
-webkit-transform: translate(-50%, -50%);
transform: translate(-50%, -50%);
width: 28px;
height: 28px;
}
.lds-ellipsis div > div {
width: 28px;
height: 28px;
border-radius: 50%;
background: #f00;
position: absolute;
top: 100px;
left: 32px;
-webkit-animation: lds-ellipsis 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
animation: lds-ellipsis 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}
.lds-ellipsis div:nth-child(1) div {
-webkit-animation: lds-ellipsis2 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
animation: lds-ellipsis2 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
background: #ff354e;
}
.lds-ellipsis div:nth-child(2) div {
-webkit-animation-delay: -0.6s;
animation-delay: -0.6s;
background: #ff354e;
}
.lds-ellipsis div:nth-child(3) div {
-webkit-animation-delay: -0.3s;
animation-delay: -0.3s;
background: #ff354e;
}
.lds-ellipsis div:nth-child(4) div {
-webkit-animation-delay: 0s;
animation-delay: 0s;
background: #ff354e;
}
.lds-ellipsis div:nth-child(5) div {
-webkit-animation: lds-ellipsis3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
animation: lds-ellipsis3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
background: #ff354e;
}
.lds-ellipsis {
width: 56px !important;
height: 56px !important;
-webkit-transform: translate(-28px, -28px) scale(0.28) translate(28px, 28px);
transform: translate(-28px, -28px) scale(0.28) translate(28px, 28px);
}

.mail_text strong img{
	width: 26px;
    position: relative;
    top: 4px;
    left: 5px;
}
.vm .mail_text strong img{
	width: 18px;
    left: 3px;
}

.license{
	position: absolute;
	bottom: 60px;
	right: 60px;
}
.license img{
	width:120px;
}
.vm .license{
    position: absolute;
    right: 5px;
    bottom: 37px;
}
.vm .license img{
	width: 60px;
    margin-right: 10px;
}

.copyright > a{
	color:#7a7a7a!important;
}

.spaceoddity{
	position: absolute;
	bottom: 20px;
	left: 0px;
}
.spaceoddity img{
	width:120px;
}

.vm .spaceoddity{
	bottom: 55px;
    left: auto;
}
.vm .spaceoddity img{
	width: 75px;
    margin-left: 4px;
}

.jquery-modal.blocker.current{
  z-index: 1000;
}


#youtubeModal {
  width:96%;
}




.notice_area{
  top: 0px;
  max-width: 1920px;
  background: #ff354e;
  color: white;
  position: fixed;
  width: 100%;
  height: 55px;
  z-index: 999999999;
  font-size: 14px;
}

.vm .notice_area{
  font-size: 12px;
}
.notice_category{
  color: white;
  float: left;
  margin-right: 15px;
  margin-top: 21px;
  margin-left: 61px;
  font-weight: bold;
}
.vm .notice_category{
  margin-left: 20px;
  margin-top:22px;
}
.notice_title{
  color: white;
  float: left;
  margin-top: 21px;
}
.vm .notice_title{
  margin-top:22px;
}

#notice_x{
  float: right;
    color: #fff;
    position: relative;
    top: 20px;
    right: 61px;
    z-index: 9999999999;
    cursor: pointer;
}
.vm #notice_x{
  top: 20px;
  right: 20px;
}