@font-face {
    font-family:'itc-avant-garde-gothic-pro';
    font-weight:700;
    font-style:normal;
    // src: url('../fonts/ITCAvantGardeStdBold.eot');
    src: local('※'),
      // url('../fonts/ITCAvantGardeStdBold.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdBold.woff2') format('woff2'),
      url('../fonts/ITCAvantGardeStdBold.woff') format('woff'),
      url('../fonts/ITCAvantGardeStdBold.ttf') format('truetype'),
      url('../fonts/ITCAvantGardeStdBold.otf') format('opentype'),
      // url('../fonts/ITCAvantGardeStdBold.eot') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdBold.svg#Graphik-Bold') format('svg');

}
@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    font-weight:500;
    font-style:normal;
    // src: url('../fonts/ITCAvantGardeStdDemi.eot');
    src: local('※'),
      // url('../fonts/ITCAvantGardeStdDemi.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdDemi.woff2') format('woff2'),
      url('../fonts/ITCAvantGardeStdDemi.woff') format('woff'),
      url('../fonts/ITCAvantGardeStdDemi.ttf') format('truetype'),
      url('../fonts/ITCAvantGardeStdDemi.otf') format('opentype'),
      // url('../fonts/ITCAvantGardeStdDemi.eot') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdDemi.svg#Graphik-Bold') format('svg');
    font-weight: 500;
    font-style: normal;
}
@font-face {
    font-family: 'itc-avant-garde-gothic-pro';
    font-weight:300;
    font-style:normal;
    // src: url('../fonts/ITCAvantGardeStdMd.eot');
    src: local('※'),
      // url('../fonts/ITCAvantGardeStdMd.eot?#iefix') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdMd.woff2') format('woff2'),
      url('../fonts/ITCAvantGardeStdMd.woff') format('woff'),
      url('../fonts/ITCAvantGardeStdMd.ttf') format('truetype'),
      url('../fonts/ITCAvantGardeStdMd.otf') format('opentype'),
      // url('../fonts/ITCAvantGardeStdMd.eot') format('embedded-opentype'),
      url('../fonts/ITCAvantGardeStdMd.svg#Graphik-Bold') format('svg');
}
