@charset "UTF-8";
/*! variable | Layout */
/* color */
/* font */
/*!
 * 폰트 정의.
 * @param  {String} $font-family [폰트 명]
 * @param  {String} $font-size   [폰트 사이즈]
 * @param  {String} $font-weight [폰트 두께]
 */
/*!
 * Float Bug fix.
 */
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
}

body {
  line-height: 1;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

@font-face {
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 700;
  font-style: normal;
  src: local("※"), url("../fonts/ITCAvantGardeStdBold.woff2") format("woff2"), url("../fonts/ITCAvantGardeStdBold.woff") format("woff"), url("../fonts/ITCAvantGardeStdBold.ttf") format("truetype"), url("../fonts/ITCAvantGardeStdBold.otf") format("opentype"), url("../fonts/ITCAvantGardeStdBold.svg#Graphik-Bold") format("svg");
}

@font-face {
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 500;
  font-style: normal;
  src: local("※"), url("../fonts/ITCAvantGardeStdDemi.woff2") format("woff2"), url("../fonts/ITCAvantGardeStdDemi.woff") format("woff"), url("../fonts/ITCAvantGardeStdDemi.ttf") format("truetype"), url("../fonts/ITCAvantGardeStdDemi.otf") format("opentype"), url("../fonts/ITCAvantGardeStdDemi.svg#Graphik-Bold") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'itc-avant-garde-gothic-pro';
  font-weight: 300;
  font-style: normal;
  src: local("※"), url("../fonts/ITCAvantGardeStdMd.woff2") format("woff2"), url("../fonts/ITCAvantGardeStdMd.woff") format("woff"), url("../fonts/ITCAvantGardeStdMd.ttf") format("truetype"), url("../fonts/ITCAvantGardeStdMd.otf") format("opentype"), url("../fonts/ITCAvantGardeStdMd.svg#Graphik-Bold") format("svg");
}

.vm.none-touch {
  pointer-events: none;
}

body {
  position: relative;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 14px;
  font-weight: 500;
  max-width: 1920px;
  margin: 0 auto;
  overflow-x: hidden;
}

body.preload * {
  transition: none !important;
}

a {
  text-decoration: none;
  color: #000;
}

#container {
  max-width: 1920px;
  margin: 0 auto;
}

.contents {
  position: relative;
}

.vp .contents {
  margin-top: 135px;
}

.vp .contents.artist-con {
  margin-top: 0;
}

.vmgnb.vm .contents {
  margin: 0;
  padding-top: 72px;
}

#header {
  z-index: 100;
  position: fixed;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: 1920px;
  padding: 35px 25px 10px 60px;
  box-sizing: border-box;
  pointer-events: none;
}

#header:after {
  display: block;
  clear: both;
  content: '';
}

.safari #header {
  perspective: 10000;
}

.vm #header {
  position: absolute;
  z-index: 100;
}

.vm #header.up {
  position: fixed;
  top: 0;
  transition: top .3s;
}

.vm #header.down {
  position: fixed;
  top: -72px;
  transition: top .3s;
}

.vm #header.ready {
  position: absolute !important;
  top: 0px;
}

.vm #header.ready.down {
  top: -72px !important;
}

.vm.board-open #header.down {
  top: -135px;
}

.vm.board-open #header.ready.down {
  top: -135px !important;
}

#header .logo {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo.move {
  padding: 0 !important;
}

#header .logo.move a {
  background-image: url("../images/logo_motion.gif");
  background-size: 100% !important;
}

#header .logo1 {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo1 a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo_1.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo1.move {
  padding: 0 !important;
}

#header .logo1.move a {
  background-image: url("../images/logo_motion_1.gif");
  background-size: 100% !important;
}

#header .logo2 {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo2 a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo_2.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo2.move {
  padding: 0 !important;
}

#header .logo2.move a {
  background-image: url("../images/logo_motion_2.gif");
  background-size: 100% !important;
}

#header .logo3 {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo3 a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo_3.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo3.move {
  padding: 0 !important;
}

#header .logo3.move a {
  background-image: url("../images/logo_motion_3.gif");
  background-size: 100% !important;
}

#header .logo4 {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo4 a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo_4.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo4.move {
  padding: 0 !important;
}

#header .logo4.move a {
  background-image: url("../images/logo_motion_4.gif");
  background-size: 100% !important;
}

#header .logo5 {
  float: left;
  width: 160px;
  height: 108px;
  pointer-events: auto;
  margin-top: -7px;
  margin-left: -7px;
  padding: 6px;
  box-sizing: border-box;
}

#header .logo5 a {
  display: block;
  height: 100%;
  color: transparent;
  background: url("../images/logo_5.svg") no-repeat 50%/147px auto;
  background-size: auto;
}

#header .logo5.move {
  padding: 0 !important;
}

#header .logo5.move a {
  background-image: url("../images/logo_motion_5.gif");
  background-size: 100% !important;
}

#header .hamburger {
  display: none;
  pointer-events: auto;
}

#header nav {
  float: right;
}

#header nav ul {
  display: block;
}

#header nav ul:after {
  display: block;
  clear: both;
  content: '';
}

#header nav li {
  float: left;
}

#header nav li a {
  position: relative;
  display: block;
  font-size: 17px;
  color: #000;
  padding: 24px 32px 30px 35px;
  box-sizing: border-box;
  pointer-events: auto;
  font-weight: 500;
  text-transform: uppercase;
}

#header nav li a:hover {
  text-decoration: underline;
}

#header nav li a .badge {
  display: block;
  position: absolute;
  top: -5px;
  left: 50%;
  margin-left: 14px;
  color: #fff;
  font-size: 11px;
  line-height: 20px;
  height: 21px;
  background: #000;
  padding: 0 8px;
  border-radius: 10px;
  font-weight: 500;
  text-transform: lowercase;
}

.safari #header nav li a {
  perspective: 10000;
}

#header.type-white .logo a {
  background-image: url("../images/logo_white.svg");
}

#header.type-white .logo.move a {
  background-image: url("../images/logo_white_motion.gif");
}

#header.type-white nav ul li a {
  color: #fff;
}

#header.type-white nav ul li a .badge {
  background: #fff;
  color: #000;
}

#header.page-detail ~ #container .contents {
  margin-top: 0;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo a {
  background-image: url("../images/logo.svg") !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo1 {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo1 a {
  background-image: url("../images/logo_1.svg") !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo2 {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo2 a {
  background-image: url("../images/logo_2.svg") !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo3 {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo3 a {
  background-image: url("../images/logo_3.svg") !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo4 {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo4 a {
  background-image: url("../images/logo_4.svg") !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo5 {
  width: 147px !important;
  height: 95px !important;
}

.header_minify:not(.vmgnb) #header.page-detail h1.logo5 a {
  background-image: url("../images/logo_5.svg") !important;
}

.vmgnb #header.page-detail h1.logo {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb #header.page-detail h1.logo1 {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb #header.page-detail h1.logo2 {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb #header.page-detail h1.logo3 {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb #header.page-detail h1.logo4 {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb #header.page-detail h1.logo5 {
  width: 164px;
  height: 112px;
  margin-top: -8px;
  margin-left: -8px;
}

.vmgnb.vm #header.page-detail h1.logo {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vmgnb.vm #header.page-detail h1.logo1 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vmgnb.vm #header.page-detail h1.logo2 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vmgnb.vm #header.page-detail h1.logo3 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vmgnb.vm #header.page-detail h1.logo4 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vmgnb.vm #header.page-detail h1.logo5 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
}

.vp:not(.vmgnb) #header nav {
  display: block !important;
}

.header_minify #header:not(.page-board) {
  background-color: #fff;
}

.header_minify #header:not(.page-board).type-white {
  background-color: #000;
}

.header_minify:not(.vmgnb) #header .logo {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header .logo1 {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header .logo2 {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header .logo3 {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header .logo4 {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header .logo5 {
  width: 100px;
  height: 70px;
  margin-top: -5px;
  margin-left: -5px;
}

.header_minify:not(.vmgnb) #header:not(.page-board) {
  height: 80px;
  padding-top: 20px;
}

.header_minify:not(.vmgnb) #header:not(.page-board) .logo {
  width: 140px;
  height: 40px;
  margin: 0;
}

.header_minify:not(.vmgnb) #header:not(.page-board) .logo a {
  background-image: url("../images/logo_mini.svg");
}

.header_minify:not(.vmgnb) #header:not(.page-board).type-white .logo a {
  background-image: url("../images/logo_white_mini.svg");
}

.header_minify:not(.vmgnb) #header.page-board nav li a {
  font-size: 15px;
}

.header_minify:not(.vmgnb) #header nav li a {
  padding-top: 15px;
  padding-bottom: 15px;
}

.header_minify:not(.vmgnb) #header nav li a .badge {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  color: transparent;
  padding: 0;
  margin-left: 22px;
  top: 4px;
}

.header_minify.vmgnb #header .logo {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .logo1 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .logo2 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .logo3 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .logo4 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .logo5 {
  width: 77px;
  height: 52px;
  margin-top: -4px;
  margin-left: -4px;
  padding: 0;
}

.header_minify.vmgnb #header .hamburger {
  top: 22px;
}

.vmgnb .preload #header:after,
.vmgnb .preload #header ul {
  display: none;
}

.vmgnb #header {
  padding: 30px 15px 30px 32px;
}

.vmgnb #header.type-white:after {
  background: #000;
  border-bottom-color: #fff;
}

.vmgnb #header .logo {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header .logo1 {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header .logo2 {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header .logo3 {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header .logo4 {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header .logo5 {
  width: 134px;
  height: 92px;
  margin-top: -8px;
  margin-left: -8px;
  padding: 6px;
}

.vmgnb #header nav {
  position: relative;
  float: right;
}

.vmgnb #header nav ul li {
  opacity: 0;
  transform: translateX(-15px);
  transition: transform .5s, opacity .3s .1s;
}

.vmgnb #header nav ul li:nth-child(1) {
  transition-delay: 0s;
}

.vmgnb #header nav ul li:nth-child(2) {
  transition-delay: 0.1s;
}

.vmgnb #header nav ul li:nth-child(3) {
  transition-delay: 0.2s;
}

.vmgnb #header nav ul li:nth-child(4) {
  transition-delay: 0.3s;
}

.vmgnb #header nav ul li a {
  padding: 25px 10px;
}

.vmgnb #header nav ul li a .badge {
  top: -2px;
}

.vmgnb #header .hamburger {
  display: block;
  position: absolute;
  margin-top: 0;
  top: 30px;
  right: 30px;
  width: 60px;
  height: 60px;
  overflow: hidden;
}

.vmgnb #header .hamburger span {
  color: transparent;
}

.vmgnb #header .hamburger span:before, .vmgnb #header .hamburger span:after {
  content: '';
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 6px;
  background: #000;
  transform: translateX(-50%);
  transition: all .2s;
}

.vmgnb #header .hamburger span:before {
  margin-top: -12px;
}

.vmgnb #header .hamburger span:after {
  margin-top: 6px;
}

.vmgnb #header.type-white .hamburger span:before,
.vmgnb #header.type-white .hamburger span:after {
  background: #fff;
}

.vmgnb #header.open {
  z-index: 120;
}

.vmgnb #header.open:after {
  opacity: 1;
}

.vmgnb #header.open nav ul li {
  opacity: 1;
  transform: translateX(0);
}

.vmgnb #header.open .hamburger span:before {
  margin: 0 !important;
  transform: rotate(45deg);
  top: 26px;
  left: 10px;
}

.vmgnb #header.open .hamburger span:after {
  margin: 0 !important;
  transform: rotate(-45deg);
  top: 26px;
  left: 10px;
}

.vmgnb #header.close nav ul li {
  opacity: 0;
  transform: translateX(15px);
}

.vmgnb.vm #header {
  padding: 13px;
}

.vmgnb.vm #header .logo {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header .logo1 {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header .logo2 {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header .logo3 {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header .logo4 {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header .logo5 {
  width: 60px;
  height: 40px;
  margin: 0;
  padding: 3px;
  margin-bottom: -40px;
}

.vmgnb.vm #header nav {
  top: 1px;
}

.vmgnb.vm #header nav ul li {
  max-width: 90px;
  margin-right: 12px;
}

.vmgnb.vm #header nav ul li a {
  padding: 15px 0;
  font-size: 11px;
  text-align: center;
}

.vmgnb.vm #header nav ul li a .badge {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  color: transparent;
  padding: 0;
  margin-left: 15px;
  top: 8px;
}

.vmgnb.vm #header .hamburger {
  width: 30px;
  height: 30px;
  top: 20px;
  right: 20px;
}

.vmgnb.vm #header .hamburger span:before,
.vmgnb.vm #header .hamburger span:after {
  width: 16px;
  height: 3px;
}

.vmgnb.vm #header .hamburger span:before {
  margin-top: -6px;
}

.vmgnb.vm #header .hamburger span:after {
  margin-top: 3px;
}

.vmgnb.vm #header.open .hamburger span:before {
  top: 14px;
  left: 7px;
}

.vmgnb.vm #header.open .hamburger span:after {
  top: 14px;
  left: 7px;
}

#footer {
  z-index: 2;
  position: relative;
  width: 100%;
  background: #000;
  color: #fff;
  padding: 60px;
  padding-top: 180px;
  box-sizing: border-box;
  overflow: hidden;
}

#footer:after {
  display: block;
  clear: both;
  content: '';
}

#footer a {
  display: inline-block;
  color: #fff;
}

#footer .logo {
  display: block;
  position: absolute;
  top: 60px;
  left: 60px;
  top: 60px;
  left: 60px;
  width: 80px;
  height: 60px;
  overflow: hidden;
  color: transparent;
  background: url(../images/kr-footer-blip-symbol.png) no-repeat 50%;
  background-size: 100%;
}

#footer .foot {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

#footer .inner {
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;
}

#footer .map_wrap {
  z-index: 1;
  position: relative;
  margin-left: auto;
  padding-bottom: 320px;
  max-width: 800px;
  width: 100%;
}

#footer .map_wrap li {
  position: relative;
}

#footer .map_wrap a {
  display: block;
  width: 100%;
  font-size: 120px;
  line-height: 140px;
  font-weight: 700;
}

#footer .map_wrap a span {
  display: inline-block;
  transition: transform .5s ease-in-out;
  white-space: nowrap;
}

#footer .map_wrap .fake-btn {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  font-size: 120px;
  line-height: 140px;
  font-weight: 700;
  text-indent: -99999px;
  cursor: pointer;
}

#footer .map_wrap .detail_wrap {
  opacity: 0;
  z-index: -1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transition: opacity .3s;
}

#footer .map_wrap .detail_wrap p {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 24px;
  font-weight: 700;
  line-height: 42px;
  max-width: 510px;
  word-break: break-word;
  padding-top: 190px;
}

#footer .map_wrap .detail_wrap figure {
  width: 100%;
}

#footer .map_wrap .detail_wrap figure img {
  z-index: -1;
  position: absolute;
  top: 0;
  left: 460px;
}

#footer .map_wrap li.on a span {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: #000;
}

.ie #footer .map_wrap li.on a span {
  color: #000;
  text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

#footer .map_wrap li.on.about .detail_wrap {
  opacity: 1;
}

#footer .map_wrap li.on.about a span {
  transform: translateX(-100%);
}

#footer .mail_text {
  position: relative;
  margin-left: auto;
  margin-bottom: 60px;
  max-width: 800px;
  width: 100%;
  font-size: 30px;
  line-height: 38px;
}

#footer .mail_text strong {
  display: block;
}

#footer .mail_text a {
  display: inline-block;
  color: #7a7a7a;
}

#footer .mail_text a:hover, #footer .mail_text a:focus {
  text-decoration: underline;
}

#footer .sns_wrap {
  position: relative;
  margin-left: auto;
  max-width: 800px;
  width: 100%;
  font-size: 30px;
  line-height: 38px;
}

#footer .sns_wrap:after {
  display: block;
  clear: both;
  content: '';
}

#footer .sns_wrap a {
  float: left;
  width: 30px;
  height: 30px;
  color: transparent;
  overflow: hidden;
  margin-left: 0;
  transition: margin .3s;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: 50%;
}

#footer .sns_wrap a + a {
  margin-left: 50px;
}

#footer .sns_wrap a.tw {
  background-image: url("../images/icon_sns_twitter.svg");
}

#footer .sns_wrap a.yt {
  background-image: url("../images/icon_sns_youtube.svg");
}

#footer .sns_wrap a.in {
  background-image: url("../images/icon_sns_instagram.svg");
}

#footer .copyright {
  position: absolute;
  left: 60px;
  bottom: 60px;
  color: #464646;
  font-size: 13px;
  line-height: 20px;
}

.vm #footer {
  padding: 100px 13px 35px;
}

.vm #footer .logo {
  top: 10px;
  left: 24px;
  width: 55px;
  height: 55px;
}

.vm #footer .foot {
  margin-top: 55px;
  margin-left: 77px;
}

.v0.vm #footer .foot {
  margin-left: 58px;
}

.vm #footer .map_wrap {
  max-width: none;
  width: auto;
  left: 0;
  margin: 0;
  margin-left: 77px;
  padding-bottom: 0;
}

.v0.vm #footer .map_wrap {
  margin-left: 58px;
}

.vm #footer .map_wrap a {
  font-size: 46px;
  line-height: 64px;
}

.vm #footer .map_wrap a span {
  position: static;
  width: auto;
  white-space: normal;
}

.vm #footer .map_wrap .detail_wrap {
  display: none;
}

.vm #footer .map_wrap .fake-btn {
  display: none;
}

.vm #footer .mail_text {
  font-size: 17px;
  line-height: 28px;
  position: static;
  width: auto;
  margin-left: 0;
}

.vm #footer .mail_text strong {
  line-height: 15px;
}

.vm #footer .sns_wrap {
  position: static;
  width: auto;
  margin-top: -40px;
  margin-left: -6px;
}

.vm #footer .sns_wrap a {
  background-size: 22px auto;
}

.vm #footer .sns_wrap a + a {
  margin-left: 30px;
}

.vm #footer .copyright {
  position: static;
  width: auto;
  margin-top: 40px;
  font-size: 10px;
}

.btn_tap {
  z-index: 1;
  position: fixed;
  position: sticky;
  float: right;
  margin-bottom: 60px;
  margin-right: 60px;
  right: 60px;
  bottom: 60px;
  margin-top: -40px;
  margin-left: -40px;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #000000;
}

.no-sticky .btn_tap {
  margin: 0;
}

.btn_tap > button {
  font-family: itc-avant-garde-gothic-pro;
  position: relative;
  display: inline-block;
  width: 100%;
  border: 0;
  padding: 0;
  text-align: center;
  font-size: 24px;
  height: 80px;
  line-height: 80px;
  vertical-align: middle;
  color: #fff;
  background: transparent;
  border-radius: 50%;
  transform: rotate(0) scale(1);
}

.btn_tap > button:before {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 136px;
  height: 136px;
  margin-left: -68px;
  margin-top: -68px;
  border-radius: 50%;
  background: #000000;
  transform: scale(0);
  transition: transform .3s;
  content: '';
}

.btn_tap > button span {
  position: relative;
  display: block;
  line-height: 1;
  vertical-align: middle;
  z-index: 1;
  transform: scale(1);
  transition: transform .2s;
}

.btn_tap .over {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 136px;
  height: 136px;
  margin-left: -68px;
  margin-top: -68px;
  border-radius: 50%;
  transform: scale(0);
  transition: transform 0.3s cubic-bezier(0.9, 0, 0.1, 1) 0.1s;
  z-index: 2;
  background: #000000;
}

.btn_tap .over > * {
  opacity: 0;
  transition: opacity .8s;
}

.btn_tap .over:after {
  content: '';
  display: block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.btn_tap .over > a:hover {
  text-decoration: underline;
}

.btn_tap .over .prev,
.btn_tap .over .next {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 30px;
  height: 20px;
  line-height: 20px;
  margin-top: -10px;
  font-size: 14px;
  color: #fff;
  text-align: center;
}

.btn_tap .over .prev.disabled,
.btn_tap .over .next.disabled {
  pointer-events: none;
  opacity: .3;
}

.btn_tap .over .prev {
  margin-left: -55px;
}

.btn_tap .over .next {
  margin-left: 25px;
}

.btn_tap .over .top,
.btn_tap .over .bottom {
  position: absolute;
  left: 50%;
  margin-left: -10px;
  color: transparent;
}

.btn_tap .over .top span,
.btn_tap .over .bottom span {
  display: block;
  width: 20px;
  height: 17px;
  background: url(../images/icon_tap_arrow_up.svg) no-repeat 50%;
  background-size: 100%;
}

.btn_tap .over .top {
  top: 16px;
}

.btn_tap .over .bottom {
  bottom: 16px;
}

.btn_tap .over .bottom span {
  background: url(../images/icon_tap_arrow_down.svg) no-repeat 50%;
}

.btn_tap .over .top:hover:before,
.btn_tap .over .bottom:hover:before {
  position: absolute;
  bottom: -4px;
  left: 50%;
  width: 10px;
  height: 1px;
  margin-left: -5px;
  background-color: #fff;
  content: '';
}

.btn_tap.active > button:before {
  transform: scale(1);
}

.btn_tap.active > button span {
  transform: scale(0);
}

.btn_tap.active .over {
  transform: scale(1);
}

.btn_tap.active .over > * {
  opacity: 1;
}

.vm .btn_tap {
  margin-right: 13px;
  margin-bottom: 13px;
  right: 13px;
  bottom: 13px;
  width: 40px;
  height: 40px;
  margin-left: -20px;
  margin-top: -20px;
}

.no-sticky .vm .btn_tap {
  margin: 0;
}

.vm .btn_tap > button {
  font-size: 11px;
  height: 40px;
  line-height: 40px;
}

.vm .btn_tap.active > button {
  transform: rotate(720deg) scale(0.1);
  transition: transform .4s ease-in-out;
}

.vm .btn_tap .over {
  width: 100px;
  height: 100px;
}

.vm .btn_tap .over .prev,
.vm .btn_tap .over .next {
  font-size: 11px;
}

.vm .btn_tap .over .prev {
  margin-left: -43px;
}

.vm .btn_tap .over .next {
  margin-left: 13px;
}

.vm .btn_tap .over .top {
  top: 13px;
}

.vm .btn_tap .over .bottom {
  bottom: 13px;
}

.v2.vp #footer .copyright {
  position: relative;
  margin-left: auto;
  left: 0px;
  bottom: 0px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v2.vp .spaceoddity {
  position: relative;
  margin-left: auto;
  left: 0px;
  bottom: -15px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v3.vp #footer .copyright {
  position: relative;
  margin-left: auto;
  left: 0px;
  bottom: 0px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v3.vp .spaceoddity {
  position: relative;
  margin-left: auto;
  left: 0px;
  bottom: -15px;
  max-width: 800px;
  width: 100%;
  line-height: 38px;
}

.v3.header_minify #header nav ul li a .badge {
  width: 7px;
  height: 7px;
  border-radius: 50%;
  color: transparent;
  padding: 0;
  margin-left: 22px;
  top: 5px;
}

.v3.vmgnb.header_minify #header nav ul li a .badge {
  top: 13px;
}

@keyframes equalizer1 {
  0% {
    height: 12px;
  }
  50% {
    height: 4px;
  }
  100% {
    height: 12px;
  }
}

@keyframes equalizer2 {
  0% {
    height: 2px;
  }
  50% {
    height: 10px;
  }
  100% {
    height: 4px;
  }
}

@keyframes equalizer3 {
  0% {
    height: 7px;
  }
  50% {
    height: 2px;
  }
  100% {
    height: 7px;
  }
}

html.scroll-lock body {
  overflow: hidden;
}

html.scroll-lock.vm,
html.scroll-lock.vm body {
  position: fixed;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  -webkit-overflow-scrolling: auto;
}

.contents, .o_inner {
  overflow: hidden;
}

.o_tabmenu {
  z-index: 150;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.vp .o_tabmenu .scroll-inner > div {
  transform: none !important;
  transition: none !important;
}

.o_tabmenu .inner {
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  box-sizing: border-box;
  width: 100%;
  max-width: 1100px;
  padding: 240px 0 80px 120px;
}

.o_tabmenu .bg {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  margin-left: -580px;
  width: 1540px;
  max-height: 100%;
  height: 100%;
  background: #fc0101;
  transform: scaleY(0);
  transform-origin: top;
  transition: transform .3s;
}

.o_tabmenu .btn_close {
  opacity: 0;
  position: absolute;
  left: 120px;
  top: 50px;
  display: block;
  width: auto;
  color: #fff;
  font-size: 46px;
  transition: opacity .3s;
}

.vp .o_tabmenu .btn_close:hover, .vp .o_tabmenu .btn_close:focus {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}

.ie .vp .o_tabmenu .btn_close:hover, .ie .vp .o_tabmenu .btn_close:focus {
  opacity: .7;
}

.o_tabmenu ul li {
  font-size: 86px;
  line-height: 100px;
  font-weight: 700;
  letter-spacing: -0.01em;
  opacity: 0;
  transform: translateX(-20px);
  transition: transform .8s, opacity .5s .1s;
}

.o_tabmenu ul li:nth-child(1) {
  transition-delay: 0s;
}

.o_tabmenu ul li:nth-child(2) {
  transition-delay: 0.1s;
}

.o_tabmenu ul li:nth-child(3) {
  transition-delay: 0.2s;
}

.o_tabmenu ul li:nth-child(4) {
  transition-delay: 0.3s;
}

.o_tabmenu ul li:nth-child(5) {
  transition-delay: 0.4s;
}

.o_tabmenu ul li:nth-child(6) {
  transition-delay: 0.5s;
}

.o_tabmenu ul li + li {
  margin-top: 10px;
}

.o_tabmenu ul li a {
  display: inline-block;
  color: #fff;
}

.o_tabmenu ul li sup {
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  vertical-align: top;
  font-weight: 500;
}

.o_tabmenu ul li.on a {
  color: #000;
  transition: color .2s;
}

.vp .o_tabmenu ul li:not(.on) a:hover,
.vp .o_tabmenu ul li:not(.on) a:focus {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}

.vp .o_tabmenu ul li:not(.on) a:hover sup,
.vp .o_tabmenu ul li:not(.on) a:focus sup {
  -webkit-text-stroke: 0;
  -webkit-text-fill-color: #fff;
}

.vp.ie .o_tabmenu ul li:not(.on) a:hover,
.vp.ie .o_tabmenu ul li:not(.on) a:focus {
  opacity: .7;
}

.o_tabmenu.open .bg {
  transform: scaleY(1);
  transition: transform .3s,background .8s;
}

.o_tabmenu.open .btn_close {
  opacity: 1;
}

.o_tabmenu.open ul li {
  opacity: 1;
  transform: translateX(0);
}

.o_tabmenu.close .bg {
  transform: scaleY(0);
  transition: transform .1s .6s;
}

.o_tabmenu.close .btn_close {
  opacity: 0;
}

.o_tabmenu.close ul li {
  opacity: 0;
  transform: translateX(15px);
}

.vm .o_tabmenu {
  z-index: 200;
  left: 0;
  right: 0;
  min-width: auto;
  width: 100%;
  transform: none;
  overflow: hidden;
}

.vm .o_tabmenu .scroll-inner {
  z-index: 2;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  box-sizing: border-box;
}

.vm .o_tabmenu .inner {
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0;
  height: 100%;
  transform: none;
}

.vm .o_tabmenu .bg {
  z-index: 1;
  height: 100vh;
  max-height: none;
}

.vm .o_tabmenu .btn_close {
  font-size: 26px;
  left: 43px;
  top: 40px;
  font-weight: 700;
}

.vm .o_tabmenu ul {
  min-height: 100%;
  padding: 130px 20px 40px 43px;
  box-sizing: border-box;
}

.vm .o_tabmenu ul li {
  font-size: 44px;
  line-height: 48px;
}

.vm .o_tabmenu ul li + li {
  margin-top: 36px;
}

.vm .o_tabmenu ul li span {
  display: block;
}

.vm .o_tabmenu ul li sup {
  display: inline-block;
  font-size: 11px;
  line-height: 1;
  vertical-align: top;
  margin-top: 5px;
  margin-left: 7px;
}

.vm .o_tabmenu ul li:not(.on) a.active {
  -webkit-text-stroke: 1px #fff;
  -webkit-text-fill-color: transparent;
}

.vm .o_tabmenu ul li:not(.on) a.active sup {
  -webkit-text-stroke: 0;
  -webkit-text-fill-color: #fff;
}

.board_header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 440px;
  max-width: 1100px;
  width: 100%;
  margin: 0 auto;
  padding-top: 110px;
  box-sizing: border-box;
}

.board_header .scroll_progress {
  opacity: 0;
  position: fixed;
  z-index: 1;
  top: 178px;
  right: 0;
  width: 100%;
  height: 1px;
  background: #f0f0f0;
  overflow: hidden;
}

.board_header .scroll_progress .bar {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  transform: translateX(-100%);
}

.header_minify .board_header {
  z-index: 99;
  position: fixed;
  padding-top: 24px;
  height: 178px;
}

.header_minify .board_header:after {
  content: '';
  z-index: -1;
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -960px;
  display: block;
  width: 1920px;
  height: 100%;
  background: #fff;
}

.header_minify .board_header .scroll_progress {
  opacity: 1;
}

.header_minify .board_header .board_info .board_title {
  font-size: 40px;
  line-height: 50px;
}

.header_minify .board_header .board_info .board_title sup {
  font-size: 10px;
  margin-left: 3px;
}

.header_minify .board_header .board_info .board_desc {
  display: none;
}

.header_minify .board_header .board_info .menu {
  right: auto;
  left: 50%;
  margin-left: 14px;
  transform: scale(0.5);
  transform-origin: 0 0;
}

.header_minify .board_header .board_sort {
  padding: 0;
  padding-top: 68px;
}

.header_minify .board_header .board_sort ul li {
  font-size: 15px;
  line-height: 24px;
}

.header_minify .board_header .board_sort ul li + li {
  margin-left: 10%;
}

.header_minify .board_header .board_sort ul li > span {
  display: none;
}

.header_minify .board_header .board_sort ul li > a {
  min-width: 120px;
  background-size: 7px auto;
}

.header_minify .board_header .board_sort ul li > a span:after {
  display: none;
}

.vm .board_header {
  transition: padding .3s;
}

.vm.board-open .board_header {
  padding-top: 120px;
}

.vm.header_minify.board-up .board_header {
  padding-top: 90px;
}

.vm.header_minify.board-up.board-open .board_header {
  padding-top: 90px;
}

.vm.header_minify .board_header {
  z-index: 90;
  height: auto;
  padding-top: 20px;
  transition: padding .3s;
}

.vm.header_minify .board_header .scroll_progress {
  display: block;
  opacity: 1;
  width: 100%;
  margin: 0;
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
}

.vm.header_minify .board_header .board_info .menu {
  left: auto;
  right: 25px;
  transform: none;
}

.vm.header_minify .board_header .board_title {
  font-size: 26px;
  line-height: 28px;
}

.vm.header_minify .board_header .board_sort {
  padding: 25px 0 15px;
}

.vm.header_minify .board_header .board_sort ul li {
  font-size: 13px;
  line-height: 20px;
}

.vm.header_minify .board_header .board_sort ul li > a {
  min-width: 60px;
}

.vm .board_header {
  z-index: 90;
  padding-top: 125px;
  height: auto;
  background: #fff;
}

.board_title {
  transform: translateZ(0);
}

.vp .board_title {
  min-height: 100px;
}

.header_minify .board_title {
  min-height: auto;
}

.board_title .flip-card {
  position: relative;
  perspective: 1600px;
  transform-style: preserve-3d;
  width: 100%;
}

.board_title .flip-card [class*=text-item] {
  width: 100%;
  display: none;
}

.board_title .flip-card .front-panel {
  background: #fff;
  transition: transform .25s;
  backface-visibility: hidden;
  transform: rotateX(0deg);
}

.board_title .flip-card .back-panel {
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: transform .25s;
  backface-visibility: hidden;
  transform: rotateX(-180deg);
  visibility: hidden;
}

.board_title.card-view-ing .back-panel {
  visibility: visible;
}

.board_title.active1 .text-item1,
.board_title.active1 .text-item2 {
  display: block;
}

.board_title.active2 .text-item2,
.board_title.active2 .text-item3 {
  display: block;
}

.board_title.active3 .text-item3,
.board_title.active3 .text-item4 {
  display: block;
}

.board_title.active4 .text-item4,
.board_title.active4 .text-item5 {
  display: block;
}

.board_title.active5 .text-item5,
.board_title.active5 .text-item6 {
  display: block;
}

.board_title.active6 .text-item6,
.board_title.active6 .text-item7 {
  display: block;
}

.board_info {
  position: relative;
  max-width: 980px;
  margin-left: 120px;
}

.board_info .board_title {
  font-size: 86px;
  line-height: 100px;
  font-weight: 700;
  letter-spacing: -0.01em;
}

.board_info .board_title span {
  -webkit-text-stroke: 1px #000;
  -webkit-text-fill-color: #fff;
}

.ie .board_info .board_title span {
  color: #fff;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.board_info .board_title sup {
  display: inline-block;
  font-size: 22px;
  line-height: 1;
  vertical-align: top;
  font-weight: 500;
}

.board_info .board_desc {
  line-height: 20px;
  padding-top: 20px;
  color: #999999;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 17px;
  font-weight: 400;
}

.board_info .menu {
  position: absolute;
  top: 0;
  right: -40px;
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
  background: #000;
  border-radius: 50%;
  color: transparent;
  text-align: center;
}

.board_info .menu i {
  display: inline-block;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  background: #fff;
  vertical-align: middle;
  transition: margin 0.2s cubic-bezier(0, 0, 0.2, 1);
}

.board_info .menu i + i {
  margin-left: 8px;
}

.board_info .menu:hover i + i, .board_info .menu:focus i + i {
  margin-left: -14px;
}

.board_info .menu.btn_close {
  background: #fff;
}

.board_info .menu.yt {
  background-color: #fc0101;
}

.board_info .menu.tw {
  background-color: #00a3ff;
}

.board_info .menu.in {
  background-color: #ff0075;
}

.board_info .menu.fb {
  background-color: #1877f2;
}

.board_info .menu.fc {
  background-color: #833ab4;
}

.vm .board_info {
  padding-right: 0;
  margin-left: 43px;
}

.vm .board_info .board_title {
  font-size: 44px;
  line-height: 48px;
  transition: all .3s;
}

.safari .vm .board_info .board_title {
  perspective: 10000;
}

.vm .board_info .board_title span {
  display: block;
}

.vm .board_info .board_title sup {
  font-size: 11px;
}

.vm .board_info .board_desc {
  padding-top: 5px;
  font-size: 12px;
  color: #999999;
}

.vm .board_info .menu {
  right: 25px;
  width: 52px;
  height: 52px;
  line-height: 52px;
}

.vm .board_info .menu i {
  width: 7px;
  height: 7px;
}

.vm .board_info .menu i + i {
  margin-left: 4px;
}

.board_sort {
  max-width: 980px;
  margin-left: 120px;
  padding: 70px 0 70px;
}

.board_sort:after {
  display: block;
  clear: both;
  content: '';
}

.board_sort li {
  position: relative;
  font-size: 23px;
  line-height: 33px;
  float: left;
  vertical-align: top;
}

.board_sort li + li {
  margin-left: 16%;
}

.board_sort li > a {
  position: relative;
  display: block;
  min-width: 150px;
  padding-right: 10px;
  box-sizing: border-box;
}

.board_sort li > a:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 0;
  margin-top: -3px;
  border-top: 6px solid #000;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}

.board_sort li > a span {
  display: inline-block;
  position: relative;
}

.board_sort li > a span:after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 4px;
  display: block;
  height: 1px;
  width: 100%;
  background: #000;
}

.board_sort li.open > a:after {
  margin-top: -10px;
  transform: rotate(180deg);
}

.board_sort .dropdown_box {
  display: none;
  z-index: 1;
  position: absolute;
  left: 0;
  top: 75px;
  width: 200px;
  background: #fff;
  border: 1px solid #000;
}

.board_sort .dropdown_box a {
  display: block;
  width: 100%;
  height: 46px;
  line-height: 46px;
  font-size: 16px;
  padding-left: 20px;
  box-sizing: border-box;
}

.board_sort .dropdown_box a + a {
  border-top: 1px solid #000;
}

.board_sort .dropdown_box a.on, .board_sort .dropdown_box a:hover, .board_sort .dropdown_box a:focus {
  background: #000;
  color: #fff;
}

.header_minify .board_sort .dropdown_box {
  top: 37px;
  width: 160px;
}

.header_minify .board_sort .dropdown_box a {
  padding-left: 15px;
}

.vm .board_sort .dropdown_box {
  width: 100px;
  top: 34px;
}

.vm .board_sort .dropdown_box a {
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  padding-left: 10px;
}

.vm.header_minify .board_sort .dropdown_box {
  top: 35px;
}

.board_sort li.gender > a span {
  color: transparent;
  white-space: nowrap;
}

.board_sort li.gender > a span:after {
  display: none;
}

.board_sort li.gender i {
  display: inline-block;
  width: 26px;
  height: 26px;
  margin-right: 12px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
}

.board_sort li.gender i.all {
  background-image: url(../images/board/icon_emoticon_all.svg);
}

.board_sort li.gender i.woman {
  background-image: url(../images/board/icon_emoticon_woman.svg);
}

.board_sort li.gender i.man {
  background-image: url(../images/board/icon_emoticon_man.svg);
}

.board_sort li.gender .dropdown_box a.on i.all,
.board_sort li.gender .dropdown_box a:hover i.all,
.board_sort li.gender .dropdown_box a:focus i.all {
  background-image: url(../images/board/icon_emoticon_all_w.svg);
}

.board_sort li.gender .dropdown_box a.on i.woman,
.board_sort li.gender .dropdown_box a:hover i.woman,
.board_sort li.gender .dropdown_box a:focus i.woman {
  background-image: url(../images/board/icon_emoticon_woman_w.svg);
}

.board_sort li.gender .dropdown_box a.on i.man,
.board_sort li.gender .dropdown_box a:hover i.man,
.board_sort li.gender .dropdown_box a:focus i.man {
  background-image: url(../images/board/icon_emoticon_man_w.svg);
}

.header_minify .board_sort li.gender i {
  width: 16px;
  height: 16px;
}

.vm .board_sort {
  padding: 0;
  padding-top: 25px;
  margin-left: 43px;
}

.vm .board_sort ul li {
  font-size: 17px;
  line-height: 25px;
}

.vm .board_sort ul li + li {
  margin-left: 10%;
}

.vm .board_sort ul li > span {
  display: none;
}

.vm .board_sort ul li > a {
  min-width: 72px;
  background-size: 7px 4px;
}

.vm .board_sort ul li > a span:after {
  bottom: 2px;
}

.vm .board_sort ul li > a:after {
  border-width: 4px;
  margin-top: -2px;
}

.vm .board_sort ul li.gender > a {
  width: 72px;
}

.vm .board_sort ul li.open > a:after {
  margin-top: -7px;
}

.vm .board_sort .gender i {
  width: 16px;
  height: 16px;
}

.board_container {
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 30px;
  background: #fff;
}

.board_header + .board_container {
  padding-top: 440px;
}

.header_minify.vp .board_header + .board_container {
  padding-top: 290px;
  transition: padding .3s;
}

.header_minify.vm .board_header + .board_container {
  padding-top: 170px;
  transition: padding .3s;
}

.board_container.alone {
  padding: 0 !important;
}

.board_container.alone .board_item .title {
  min-height: 34px;
  line-height: 34px;
}

.board_container.alone .board_item .title strong {
  display: inline-block;
  vertical-align: middle;
}

.vm .board_container.alone.list-youtube {
  margin: 0 -15px;
}

.vm .board_container.alone.list-youtube .board_item .total {
  padding-bottom: 0;
}

.vm .board_container {
  padding-top: 230px;
  padding-bottom: 30px;
  overflow: hidden;
  transition: all .3s;
}

.vm.board-open .board_container {
  padding-top: 220px;
  transition: all .3s;
}

.board_content_info {
  position: relative;
  min-height: 17px;
  margin-left: 120px;
  border-bottom: 1px solid #e5e5e5;
  padding: 20px 0 25px;
}

.board_content_info .info_sort {
  display: inline-block;
}

.board_content_info .info_sort .date {
  display: none;
}

.board_content_info .info_sort li {
  position: relative;
  width: auto;
  display: inline-block;
  margin-right: 36px;
}

.board_content_info .info_sort li + li {
  margin-right: 0;
}

.board_content_info .info_sort li a {
  display: block;
  position: relative;
  box-sizing: border-box;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 500;
}

.board_content_info .info_sort li > a {
  display: inline-block;
  width: auto;
  padding-right: 20px;
  line-height: 20px;
}

.board_content_info .info_sort li > a:after {
  position: absolute;
  top: 0;
  right: 0;
  content: '';
  display: block;
  width: 9px;
  height: 100%;
  content: "";
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 0;
  margin-top: -3px;
  border-top: 6px solid #000;
  border-right: 6px solid transparent;
  border-bottom: 6px solid transparent;
  border-left: 6px solid transparent;
}

.board_content_info .info_sort li.open > a:after {
  transform: rotate(180deg);
}

.board_content_info .info_sort .dropdown_box {
  display: none;
  z-index: 3;
  position: absolute;
  left: 0;
  top: 30px;
  min-width: 100%;
  background: #fff;
  border: 1px solid #000;
}

.board_content_info .info_sort .dropdown_box a {
  padding: 0 14px;
  height: 40px;
  line-height: 40px;
  white-space: nowrap;
  width: 100%;
}

.board_content_info .info_sort .dropdown_box a + a {
  border-top: 1px solid #000;
}

.board_content_info .info_sort .dropdown_box a.on, .board_content_info .info_sort .dropdown_box a:hover, .board_content_info .info_sort .dropdown_box a:focus {
  background: #000;
  color: #fff;
}

.board_content_info .info_right {
  float: right;
}

.board_content_info .date {
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  font-weight: 300;
  color: #999;
  vertical-align: middle;
  margin-right: 15px;
}

.board_content_info a.refresh {
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px;
  overflow: hidden;
  color: transparent;
  vertical-align: sub;
}

.board_content_info a.refresh:after {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: rotate(0deg);
  transform-origin: 50%;
  background: url(../images/board/icon_refresh.svg) no-repeat 50% 50%;
  transition: transform .3s ease-in-out;
}

.board_content_info a.refresh:hover:after {
  transform: rotate(-360deg);
}

.vm .board_content_info {
  margin-left: 43px;
  padding: 11px 0;
  padding-right: 15px;
}

.vm .board_content_info .info_sort .date {
  display: block;
  width: 100%;
  margin: 0;
  font-size: 10px;
  line-height: 17px;
  margin-top: 5px;
}

.vm .board_content_info .info_sort li {
  margin-right: 20px;
}

.vm .board_content_info .info_sort li a {
  font-size: 12px;
}

.vm .board_content_info .info_sort li > a {
  padding-right: 15px;
  line-height: 22px;
}

.vm .board_content_info .info_sort li > a:after {
  border-width: 4px;
  margin-top: -2px;
}

.vm .board_content_info .info_sort .dropdown_box a {
  height: 30px;
  line-height: 30px;
  padding: 0 10px;
}

.vm .board_content_info .info_right {
  width: 100%;
}

.vm .board_content_info .date {
  display: none;
}

.vm .board_content_info a.refresh {
  position: absolute;
  bottom: 11px;
  right: 15px;
  width: 17px;
  height: 17px;
}

.board_paging {
  width: 100%;
  text-align: center;
  font-size: 0;
  margin: 20px 0;
  padding-left: 120px;
  box-sizing: border-box;
}

.board_paging a {
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  font-size: 16px;
  line-height: 39px;
  border: 2px solid #000;
  background-color: #fff;
  box-sizing: border-box;
  color: #000;
  margin: 5px 15px;
}

.board_paging a.on, .board_paging a:hover, .board_paging a:focus {
  color: #fff;
  background-color: #000;
}

.vm .board_paging {
  padding-left: 0;
}

.vm .board_paging a {
  width: 30px;
  height: 30px;
  line-height: 31px;
  font-size: 12px;
  border-width: 1px;
  margin: 5px 12px;
}

.ko .board_content_head .title,
.ko .board_content_head .views,
.ko .board_content_head .total,
.ko .board_content_head .variation,
.ko .board_content_head .release,
.ko .board_content_head .link,
.ko .board_content_head .peak {
  font-weight: 500 !important;
  font-family: "Noto Sans KR", sans-serif;
}

.ko .board_sort a span {
  font-size: 21px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.ko .board_sort .dropdown_box a span {
  font-size: 16px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.header_minify .ko .board_sort a span {
  font-size: 15px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.header_minify .ko .board_sort .dropdown_box a span {
  font-size: 15px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm .ko .board_sort a span {
  font-size: 15px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm.header_minify .ko .board_header .board_sort a span {
  font-size: 13px;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm.header_minify .board_title span {
  display: initial;
}

.vm .ko .board_sort .dropdown_box a span {
  font-size: 12px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.vm.header_minify .ko .board_sort .dropdown_box a span {
  font-size: 10px !important;
  font-weight: bold !important;
  font-family: "Noto Sans KR", sans-serif;
}

.board_content_head,
.board_content_cont .board_item {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.board_content_head > div,
.board_content_cont .board_item > div {
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  box-sizing: border-box;
}

.board_content_head .ranking,
.board_content_cont .board_item .ranking {
  flex-basis: 120px;
  flex-grow: 0;
  text-align: left;
  min-width: 120px;
}

.board_content_head .new, .board_content_head .ranking.freeze,
.board_content_cont .board_item .new,
.board_content_cont .board_item .ranking.freeze {
  color: #ff354e;
}

.board_content_head .active .new, .board_content_head .active .ranking.freeze,
.board_content_cont .board_item .active .new,
.board_content_cont .board_item .active .ranking.freeze {
  color: #ffffff;
}

.board_content_head .title,
.board_content_cont .board_item .title {
  flex-basis: 41%;
  text-align: left;
}

.vp .list-youtube .board_content_head .views,
.vp .list-youtube .board_content_head .total,
.vp .list-youtube .board_content_head .peak,
.vp .list-youtube .board_content_head .release, .vp .list-youtube
.board_content_cont .board_item .views,
.vp .list-youtube
.board_content_cont .board_item .total,
.vp .list-youtube
.board_content_cont .board_item .peak,
.vp .list-youtube
.board_content_cont .board_item .release {
  margin-left: 55px;
  flex-grow: 0;
  min-width: 100px;
}

.vp .list-youtube .board_content_head .total, .vp .list-youtube
.board_content_cont .board_item .total {
  margin-left: 0;
}

.vp .list-youtube .board_content_head .peak, .vp .list-youtube
.board_content_cont .board_item .peak {
  white-space: nowrap;
  font-weight: 300;
}

.vp .list-youtube .board_content_head .release, .vp .list-youtube
.board_content_cont .board_item .release {
  font-weight: 300;
  margin-right: 40px;
}

.vp .list-youtube .board_content_head .title, .vp .list-youtube
.board_content_cont .board_item .title {
  width: calc(41% - 40px);
}

.vp .list-youtube .board_content_head .clickable, .vp .list-youtube
.board_content_cont .board_item .clickable {
  display: none;
}

.vp .list-instagram .board_content_head .total,
.vp .list-instagram .board_content_head .variation,
.vp .list-instagram .board_content_head .link, .vp .list-instagram
.board_content_cont .board_item .total,
.vp .list-instagram
.board_content_cont .board_item .variation,
.vp .list-instagram
.board_content_cont .board_item .link {
  flex-basis: auto;
  flex-grow: 0;
}

.vp .list-instagram .board_content_head .total, .vp .list-instagram
.board_content_cont .board_item .total {
  min-width: 110px;
}

.vp .list-instagram .board_content_head .variation, .vp .list-instagram
.board_content_cont .board_item .variation {
  min-width: 80px;
  margin-left: 35px;
}

.vp .list-instagram .board_content_head .link, .vp .list-instagram
.board_content_cont .board_item .link {
  min-width: 90px;
  margin-left: 70px;
}

.vm .board_content_head .total, .vm
.board_content_cont .board_item .total {
  text-align: left;
}

.board_content_head {
  padding: 35px 0;
}

.board_content_head > div {
  text-transform: uppercase;
  font-size: 12px;
  letter-spacing: 0.05em;
}

.board_content_head .title {
  padding-left: 20px;
}

.list-instagram .board_content_head .title {
  padding-left: 72px;
}

.list-youtube .board_content_head .views,
.list-youtube .board_content_head .total {
  color: #ff354e;
}

.flex-cell {
  position: relative;
  z-index: 1;
}

.vp .list-youtube .flex-cell {
  height: 102px;
}

.vp .alone .flex-cell {
  height: auto;
}

.board_content_cont span.vari i {
  display: inline-block;
  width: 7px;
  height: 4px;
  color: transparent;
  vertical-align: middle;
  margin-right: 7px;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.board_content_cont span.vari.up i {
  background-image: url(../images/board/icon_rank_up.svg);
}

.board_content_cont span.vari.down i {
  background-image: url(../images/board/icon_rank_down.svg);
}

.board_content_cont span.vari.none i {
  width: 9px;
  height: 2px;
  background: #000;
  margin-left: 6px;
}

.board_content_cont .board_item {
  position: relative;
  padding: 25px 0;
  box-sizing: border-box;
  overflow: hidden;
}

.board_content_cont .board_item:after {
  opacity: 0;
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  margin-left: -580px;
  width: 1540px;
  height: 100%;
  background: #ff354e;
  pointer-events: none;
  transform: scaleY(0);
  transform-origin: bottom;
}

.board_content_cont .board_item > div {
  word-break: break-word;
  font-size: 13px;
}

.board_content_cont .board_item .ranking {
  position: relative;
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 16px;
  font-weight: 400;
}

.board_content_cont .board_item .ranking span.vari {
  position: absolute;
  left: 45px;
  top: 50%;
  transform: translateY(-50%);
  font-family: "itc-avant-garde-gothic-pro", sans-serif;
  font-size: 12px;
  font-weight: 300;
}

.board_content_cont .board_item .title {
  position: relative;
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: #666;
}

.board_content_cont .board_item .title .text-wrap {
  width: calc(100% - 136px);
  overflow: hidden;
  margin-left: auto;
  padding-right: 40px;
}

.board_content_cont .board_item .title strong {
  display: block;
  font-size: 17px;
  font-weight: 700;
  line-height: 29px;
  color: #000;
  width: 100%;
  max-width: 390px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.board_content_cont .board_item .title a.view_yt {
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.board_content_cont .board_item .title a.view_yt figure {
  position: relative;
  width: 72px;
  height: 42px;
  overflow: hidden;
  transition: transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.board_content_cont .board_item .title a.view_yt img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
  transition: transform .3s;
}

.board_content_cont .board_item .title a.view_yt:after {
  content: '';
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0;
  transition: opacity 0.3s, transform 0.4s cubic-bezier(0.22, 0.61, 0.36, 1);
}

.board_content_cont .board_item .title a.view_yt:before {
  content: '';
  z-index: 2;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(../images/board/icon_view_yt.svg) no-repeat 50%/20px 27px;
  opacity: 0;
}

.list-instagram .board_content_cont .board_item .title {
  min-height: 40px;
  padding-left: 72px;
  line-height: 36px;
}

.list-instagram .board_content_cont .board_item .title figure {
  position: absolute;
  left: 0;
  top: 50%;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  transform: translateY(-50%);
}

.list-instagram .board_content_cont .board_item .title figure img {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  max-width: 40px;
  transform: translate(-50%, -50%);
}

.list-instagram .board_content_cont .board_item .title a.view_sg {
  position: relative;
  vertical-align: middle;
}

.list-instagram .board_content_cont .board_item .title a.view_sg:after {
  content: '';
  display: block;
  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
  height: 1px;
  background: #000;
}

.board_content_cont .board_item p {
  position: relative;
  display: inline-block;
}

.board_content_cont .chart_area {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height .3s;
}

.board_content_cont .chart_area .inner {
  opacity: 0;
  position: relative;
  overflow: hidden;
  height: auto;
  transition: opacity .1s;
}

.board_content_cont .chart_area .chart img {
  display: block;
}

.board_content_cont .chart_area .chart .pc {
  display: block;
}

.vm .board_content_cont .chart_area .chart .pc {
  display: none;
}

.board_content_cont .chart_area .chart .mob {
  display: none;
}

.vm .board_content_cont .chart_area .chart .mob {
  display: block;
  max-width: 375px;
  margin: 0 auto;
}

.board_content_cont .chart_area:after {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 50%;
  margin-left: -580px;
  width: 1540px;
  height: 100%;
  background: #ff354e;
}

.board_content_cont .chart_area .btn_chart_close {
  position: absolute;
  left: 50%;
  bottom: 22px;
  width: 42px;
  height: 42px;
  margin-left: -21px;
  border-radius: 50%;
  color: transparent;
  overflow: hidden;
  background: #fff url(../images/board/icon_chart_close.svg) no-repeat 50% 50%;
  pointer-events: all;
}

.board_content_cont .chart_area .period_box {
  position: absolute;
  left: auto;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.board_content_cont .chart_area .period_box a {
  display: block;
  font-size: 15px;
  width: 38px;
  height: 38px;
  line-height: 40px;
  text-align: center;
  color: #fff;
  border-radius: 50%;
  border: 2px solid transparent;
  pointer-events: all;
}

.board_content_cont .chart_area .period_box a + a {
  margin: 0;
  margin-top: 21px;
}

.board_content_cont .chart_area .period_box a:hover, .board_content_cont .chart_area .period_box a.on {
  border-color: #fff;
  transition: border .3s;
}

.list-youtube .board_content_cont a.view_sg {
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 22px;
  margin-right: 10px;
  padding: 0 0 0 10px;
  box-sizing: border-box;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.list-youtube .board_content_cont a.view_sg span {
  margin-left: -10px;
  transition: margin .3s;
}

.list-youtube .board_content_cont a.view_sg:after {
  opacity: 0;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: block;
  border-radius: 14px;
  border: 1px solid #fff;
  background: url(../images/board/icon_arrow_right.svg) no-repeat;
  background-size: 11px 100%;
  background-position: calc(100% - 9px) 50%;
}

.list-youtube .board_content_cont .preview_area {
  position: absolute;
  z-index: -2;
  top: 0;
  left: 50%;
  margin-left: -580px;
  width: 1540px;
  height: 100%;
  background: #000;
  pointer-events: none;
  overflow: hidden;
  opacity: 0;
  transition: height .5s, opacity .5s;
}

.list-youtube .board_content_cont .preview_area img {
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  transform: translateY(-50%);
  opacity: 0;
  transition: opacity .5s;
}

.list-youtube .board_content_cont a.btn_chart {
  opacity: 1;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: 1;
  border: 1px solid black;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  color: transparent;
  transform: translateY(-50%);
}

.list-youtube .board_content_cont a.btn_chart i {
  display: block;
  left: 50%;
  bottom: 9px;
  height: 4px;
  transform: translateX(-50%);
}

.list-youtube .board_content_cont a.btn_chart i, .list-youtube .board_content_cont a.btn_chart i:after, .list-youtube .board_content_cont a.btn_chart i:before {
  position: absolute;
  display: block;
  width: 1px;
  background: #000000;
}

.list-youtube .board_content_cont a.btn_chart i:after, .list-youtube .board_content_cont a.btn_chart i:before {
  content: '';
  bottom: 0;
}

.list-youtube .board_content_cont a.btn_chart i:after {
  height: 12px;
  margin-left: -4px;
}

.list-youtube .board_content_cont a.btn_chart i:before {
  height: 7px;
  margin-left: 4px;
}

.list-instagram .board_content_cont a.btn_chart {
  opacity: 1;
  -webkit-transition: opacity .3s;
  transition: opacity .3s;
  opacity: 1;
  border: 1px solid black;
  z-index: 1;
  position: absolute;
  top: 50%;
  right: 75px;
  display: block;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #fff;
  overflow: hidden;
  color: transparent;
  transform: translateY(-50%);
}

.list-instagram .board_content_cont a.btn_chart i {
  display: block;
  left: 50%;
  bottom: 9px;
  height: 4px;
  transform: translateX(-50%);
}

.list-instagram .board_content_cont a.btn_chart i, .list-instagram .board_content_cont a.btn_chart i:after, .list-instagram .board_content_cont a.btn_chart i:before {
  position: absolute;
  display: block;
  width: 1px;
  background: #000000;
}

.list-instagram .board_content_cont a.btn_chart i:after, .list-instagram .board_content_cont a.btn_chart i:before {
  content: '';
  bottom: 0;
}

.list-instagram .board_content_cont a.btn_chart i:after {
  height: 12px;
  margin-left: -4px;
}

.list-instagram .board_content_cont a.btn_chart i:before {
  height: 7px;
  margin-left: 4px;
}

.list-instagram .board_content_cont a.view_sg {
  display: inline-block;
  width: auto;
}

.list-instagram .board_content_cont .btn_link {
  display: inline-block;
  width: 30px;
  height: 30px;
  color: transparent;
  overflow: hidden;
  background-size: 100%;
  background-repeat: no-repeat;
  background-image: url(../images/board/kr-chart-link-nor.svg);
}

.vmgnb.vp .board_info .menu {
  right: 0;
}

.vmgnb.vp .board_content_cont .board_item .ranking {
  padding-left: 10px;
}

.vmgnb.vp .board_content_cont .board_item .ranking span {
  left: 48px;
}

.vp .board_content_cont .active {
  height: auto;
}

.vp .board_content_cont .active .board_item {
  color: #fff;
  overflow: visible;
}

.vp .board_content_cont .active .board_item:after {
  opacity: 1;
  transform: scaleY(1);
  transition: transform .2s ease-out;
}

.vp .board_content_cont .active .board_item .ranking.freeze {
  color: #fff;
}

.vp .board_content_cont .active .board_item .new {
  color: #fff;
}

.vp .board_content_cont .active .board_item .title a.view_sg {
  color: #fff;
  padding: 0 30px 0 10px;
}

.vp .board_content_cont .active .board_item .title a.view_sg span {
  margin-left: 0;
}

.vp .board_content_cont .active .board_item .title a.view_sg:after {
  opacity: 1;
  transition: opacity .3s .05s;
}

.vp .board_content_cont .active .board_item .title a.view_yt figure {
  transform: scale(1.4);
}

.vp .board_content_cont .active .board_item .title a.view_yt:after {
  transform: scale(1.4);
  opacity: .3;
}

.vp .board_content_cont .active .board_item .title a.view_yt:before {
  opacity: 1;
  transition: opacity .3s;
}

.vp .board_content_cont .active .board_item .title,
.vp .board_content_cont .active .board_item .title strong {
  color: #fff;
}

.vp .board_content_cont .active .board_item a.btn_chart {
  opacity: 1;
  border: 1px solid #fff;
  transition: opacity .3s;
}

.vp .board_content_cont .active .board_item a.btn_chart i {
  background: #ff0000;
  animation: 1s ease 1 equalizer2;
}

.vp .board_content_cont .active .board_item a.btn_chart i:after {
  background: #ff0000;
  animation: 1s ease 1 equalizer1;
}

.vp .board_content_cont .active .board_item a.btn_chart i:before {
  background: #ff0000;
  animation: 1s ease 1 equalizer3;
}

.vp .board_content_cont .active .board_item a.btn_chart:hover i {
  animation: 1s ease infinite equalizer2;
}

.vp .board_content_cont .active .board_item a.btn_chart:hover i:after {
  animation: 1s ease infinite equalizer1;
}

.vp .board_content_cont .active .board_item a.btn_chart:hover i:before {
  animation: 1s ease infinite equalizer3;
}

.vp .board_content_cont .active .board_item span.vari.up i {
  background-image: url(../images/board/icon_rank_up_w.svg);
}

.vp .board_content_cont .active .board_item span.vari.down i {
  background-image: url(../images/board/icon_rank_down_w.svg);
}

.vp .board_content_cont .active .board_item span.vari.none i {
  background: #fff;
}

.vp .board_content_cont .active .board_item .btn_link {
  background-image: url(../images/board/kr-chart-link-hover.svg);
}

.vp .board_content_cont .active .chart_area {
  overflow: visible;
}

.vp .board_content_cont .active.chart_on .chart_area {
  display: block;
  height: auto;
  max-height: 800px;
  transform: scaleY(1);
}

.vp .board_content_cont .active.chart_on .chart_area .inner {
  pointer-events: auto;
  opacity: 1;
  transition: opacity .3s .3s;
}

.vp .board_content_cont .active.chart_on .chart_area .chart img {
  display: block;
}

.vp .board_content_cont .topfive {
  height: auto;
  min-height: 102px;
  transition: max-height .4s, height .4s, min-height .4s;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.vp .board_content_cont .topfive.active {
  min-height: 320px;
  max-height: 320px;
}

.vp .board_content_cont .topfive.active .board_item:after {
  opacity: 0;
  transition: opacity .2s .3s;
}

.vp .board_content_cont .topfive.active .preview_area {
  opacity: 1;
}

.vp .board_content_cont .topfive.active .preview_area img.on {
  opacity: 1;
}

.vp .board_content_cont .topfive.active.chart_on {
  height: auto;
  max-height: 800px;
  transition: max-height .3s, height .3s, min-height .3s;
}

.vp .board_content_cont .topfive.active.chart_on .board_item:after {
  opacity: 1;
  transition: opacity 0s;
}

.vp .board_content_cont .topfive.active.chart_on .preview_area {
  opacity: 0;
}

.vp .board_content_cont .topfive.active.chart_on .preview_area img {
  opacity: 0;
  transition: none;
}

.vp .list-instagram .board_content_cont .active .board_item .title a.view_sg:after {
  background: #fff;
}

.vm .board_content_head {
  padding: 20px 0 15px;
}

.vm .board_content_head .ranking {
  min-width: auto;
  max-width: 43px;
}

.vm .list-youtube .board_content_head .views,
.vm .list-youtube .board_content_head .peak,
.vm .list-youtube .board_content_head .release {
  display: none;
}

.vm .list-youtube .board_content_head .total {
  flex-basis: 76px;
  padding-left: 30px;
  padding-right: 0;
}

.vm .list-instagram .board_content_head .link {
  display: none;
}

.vm .list-instagram .board_content_head .title {
  flex-basis: 30%;
  padding-left: 60px;
}

.vm .list-instagram .board_content_head .total {
  padding-left: 18px;
  flex-basis: 96px;
  flex-grow: 0;
}

.vm .list-instagram .board_content_head .total span {
  display: none;
}

.vm .list-instagram .board_content_head .variation {
  flex-basis: 80px;
  flex-grow: 0;
  padding-right: 45px;
}

.vm .board_content_head > div {
  font-size: 10px;
}

.vm .board_content_head .title {
  padding-left: 16px;
}

.vm .board_content_cont .flex-area {
  width: 100%;
  overflow: hidden;
}

.vm .board_content_cont span.vari i {
  width: 5px;
  height: 3px;
  background-size: 5px auto;
  margin-right: 5px;
}

.vm .board_content_cont span.vari.none i {
  width: 6px;
  height: 1px;
}

.vm .board_content_cont .chart_on span.vari.up i {
  background-image: url(../images/board/icon_rank_up_w.svg);
}

.vm .board_content_cont .chart_on span.vari.down i {
  background-image: url(../images/board/icon_rank_down_w.svg);
}

.vm .board_content_cont .chart_on span.vari.none i {
  background: #fff;
}

.vm .board_content_cont .board_item {
  padding: 19px 0;
}

.vm .board_content_cont .board_item > div {
  font-size: 10px;
}

.vm .board_content_cont .board_item .ranking {
  min-width: auto;
  text-align: center;
  font-size: 13px;
  line-height: 16px;
  flex-basis: 43px;
  flex-grow: 0;
}

.vm .board_content_cont .board_item .ranking span {
  position: static;
  display: inline-block;
  width: 100%;
  font-size: 10px;
  line-height: 11px;
  margin-top: 8px;
}

.vm .board_content_cont .board_item .title {
  padding-left: 73px;
  overflow: hidden;
}

.vm .board_content_cont .board_item .title a.view_yt figure {
  width: 60px;
  height: 34px;
}

.vm .board_content_cont .board_item .title a.view_yt:before {
  opacity: 1;
  background-size: 13px auto;
}

.vm .board_content_cont .board_item .title a.view_yt:after {
  opacity: .2;
}

.vm .board_content_cont .board_item .title strong {
  font-size: 13px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vm .board_content_cont .board_item .title .text-wrap {
  width: 100%;
  padding-right: 0;
}

.vm .board_content_cont .board_item a.btn_chart {
  display: none;
  opacity: 1;
  left: -21px;
  width: 17px;
  height: 17px;
  margin-top: -2px;
  background-image: url("../images/board/ico_graph_mo1.svg");
  background-size: 100%;
}

.vm .board_content_cont .board_item a.btn_chart i {
  display: none;
}

.vm .board_content_cont .detail_area {
  box-sizing: border-box;
  background: #ff354e;
  color: #fff;
  height: auto;
  max-height: 0;
  overflow: hidden;
  transition: max-height .2s;
}

.vm .board_content_cont .detail_area > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 23px;
  margin-left: 43px;
  margin-right: 34px;
}

.vm .board_content_cont .detail_area > div.views {
  padding-top: 17px;
}

.vm .board_content_cont .detail_area > div.peak {
  font-weight: 300;
}

.vm .board_content_cont .detail_area > div.release {
  padding-bottom: 17px;
  font-weight: 300;
}

.vm .board_content_cont .detail_area > div strong {
  font-size: 10px;
  flex-grow: 1;
  flex-basis: 41%;
}

.vm .board_content_cont .detail_area > div span {
  flex-basis: 140px;
  flex-grow: 1;
  font-size: 11px;
  text-align: right;
}

.vm .board_content_cont .chart_area {
  overflow: hidden;
  left: 0;
  right: 0;
  width: 100%;
  height: auto;
  max-height: 0;
  margin: 0;
  z-index: 2;
}

.vm .board_content_cont .chart_area img {
  width: 100%;
}

.vm .board_content_cont .chart_area:after {
  left: 0;
  right: 0;
  width: 100%;
  margin: 0;
}

.vm .board_content_cont .chart_area .chart {
  padding-bottom: 25px;
}

.vm .board_content_cont .chart_area .period_box {
  position: static;
  transform: none;
  width: 100%;
  text-align: right;
  padding: 20px 13px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.vm .board_content_cont .chart_area .period_box a {
  display: inline-block;
  width: 32px;
  height: 32px;
  line-height: 34px;
  font-size: 14px;
  margin-top: 0;
  border-width: 2px;
  font-weight: 700;
}

.vm .board_content_cont .chart_area .period_box a + a {
  margin-left: 11px;
}

.chart_on.active .btn_chart {
  display: none !important;
}

.list-youtube .chart_on.active .btn_chart2 {
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 28px;
  height: 28px;
  margin-top: -2px;
  margin-right: 12px;
  background-image: url(../images/board/kr-chart-btn-colse.svg) !important;
  background-size: 100%;
}

.ie .list-youtube .chart_on.active .btn_chart2 {
  margin-top: -13px;
}

.list-instagram .chart_on.active .btn_chart2 {
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 28px;
  height: 28px;
  margin-top: -2px;
  margin-right: 76px;
  background-image: url(../images/board/kr-chart-btn-colse.svg) !important;
  background-size: 100%;
}

.vm .list-youtube .board_content_cont .views {
  font-weight: 500;
}

.vm .list-youtube .board_content_cont .board_item .clickable {
  z-index: 1;
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  border: 0;
  padding: 0;
  background: transparent;
}

.vm .list-youtube .board_content_cont .board_item .clickable:after {
  content: '';
  display: block;
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  right: 13px;
  margin-top: -9px;
  border-top: 4px solid #000;
  border-right: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid transparent;
}

.vm .list-youtube .board_content_cont .board_item .btn_chart2 {
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 20px;
  height: 20px;
  margin-top: -8px;
  margin-right: 10px;
  background-image: url(../images/board/ico_graph_mo1.svg);
  background-size: 100%;
}

.vm .list-youtube .board_content_cont .board_item .views,
.vm .list-youtube .board_content_cont .board_item .peak,
.vm .list-youtube .board_content_cont .board_item .release {
  display: none;
}

.vm .list-youtube .board_content_cont .board_item .total {
  flex-basis: 110px;
  font-size: 11px;
  padding-left: 25px;
  padding-right: 25px;
  padding-bottom: 14px;
}

.vm .list-youtube .board_content_cont .board_item a.view_sg {
  font-size: 12px;
  padding: 0;
  height: auto;
  line-height: 17px;
  margin-right: 10px;
  transition: none;
}

.vm .list-youtube .board_content_cont .board_item a.view_sg span {
  margin: 0;
}

.vm .list-youtube .board_content_cont .board_item a.view_sg:after {
  opacity: 1;
  top: auto;
  right: auto;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: #666;
  border: 0;
}

.vm .list-youtube .board_content_cont .active .board_item .clickable:after {
  transform: rotate(180deg);
  margin-top: -14px;
  border-top-color: #ff354e;
}

.vm .list-youtube .board_content_cont .active.chart_on .board_item .clickable:after {
  border-top-color: #fff;
}

.vm .list-instagram .board_content_cont .board_item .title {
  padding-left: 60px;
  flex-basis: 30%;
  height: 46px;
  line-height: 46px;
  width: auto;
}

.vm .list-instagram .board_content_cont .board_item .title strong {
  max-width: inherit;
  width: 100%;
}

.vm .list-instagram .board_content_cont .board_item .title figure {
  width: 46px;
  height: 46px;
}

.vm .list-instagram .board_content_cont .board_item .title figure img {
  max-width: 46px;
}

.vm .list-instagram .board_content_cont .board_item .title a.view_sg {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: auto;
  max-width: 100%;
}

.vm .list-instagram .board_content_cont .board_item .total {
  padding-left: 30px;
  flex-basis: 96px;
  flex-grow: 0;
}

.vm .list-instagram .board_content_cont .board_item .variation {
  flex-basis: 110px;
  flex-grow: 0;
  padding-right: 25px;
}

.vm .list-instagram .board_content_cont .board_item .link {
  display: none;
}

.vm .list-instagram .board_content_cont .board_item .btn_chart2 {
  z-index: 1;
  display: block;
  position: absolute;
  right: 0;
  width: 30px;
  height: 100%;
  width: 20px;
  height: 20px;
  margin-top: -2px;
  margin-right: 8px;
  background-image: url(../images/board/ico_graph_mo1.svg);
  background-size: 100%;
}

.vm.header_minify.board-up .menu {
  width: 32px;
  height: 32px;
}

.vm.header_minify.board-up .board_info .menu i {
  top: -10px;
  width: 5px;
  height: 5px;
  position: relative;
}

.vm .board_content_cont .flex-cell {
  height: auto;
  transition: max-height .2s;
}

.vm .list-youtube .board_content_cont .active {
  height: auto;
  max-height: 300px;
}

.vm .board_content_cont .active .detail_area {
  height: auto;
  max-height: 200px;
}

.vm .board_content_cont .active.chart_on {
  height: auto;
  max-height: 600px;
}

.vm .board_content_cont .active.chart_on span.vari i {
  background-position: 0 100%;
}

.vm .board_content_cont .active.chart_on span.vari.none i {
  background: #fff;
}

.vm .board_content_cont .active.chart_on .board_item {
  color: #fff;
}

.vm .board_content_cont .active.chart_on .board_item:after {
  transform: scaleY(1);
  opacity: 1;
  transition: opacity .2s;
}

.vm .board_content_cont .active.chart_on .board_item .title a.view_sg {
  color: #fff;
}

.vm .board_content_cont .active.chart_on .board_item .title a.view_sg:after {
  background: #fff;
}

.vm .board_content_cont .active.chart_on .board_item .title,
.vm .board_content_cont .active.chart_on .board_item .title strong {
  color: #fff;
}

.vm .board_content_cont .active.chart_on .board_item a.btn_chart {
  display: none;
}

.vm .board_content_cont .active.chart_on .chart_area {
  height: auto;
  max-height: 490px;
}

.vm .board_content_cont .active.chart_on .chart_area .inner {
  opacity: 1;
  transition: opacity .3s .2s;
}

.vm .board_content_cont .active.chart_on .detail_area {
  height: 80px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.detail_area {
  display: none;
}

.vm .detail_area {
  display: block;
}

.vm .chart_on.active .btn_chart2 {
  background-image: url(../images/board/kr-chart-btn-colse.svg) !important;
}

.vm .btn_chart_close {
  display: none;
}

.vm .active.chart_on .no_artist_board {
  color: #ffffff;
}

.vm .active .no_artist_board {
  color: #666;
}

.active .no_artist_board {
  color: #ffffff;
}

.vm .no_artist_board {
  font-size: 12px;
  height: 20px;
}

.no_artist_board {
  font-size: 14px;
  position: relative;
  display: inline-block;
  height: 24px;
  line-height: 22px;
  margin-right: 10px;
  padding: 0 0px 0 0px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #666;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 300px;
}

.vm .board_info .menu {
  top: -4px;
}

.tab_info {
  background-image: url("/@resources/images/artist/kr-chartdetail-btn-info2.svg");
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 8px;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-size: 100% auto;
  cursor: pointer;
}

.vm .tab_info {
  width: 12px;
  height: 14px;
  margin-right: 4px;
}

#ui-datepicker-div {
  display: none;
}

.v3 .board_title .flip-card {
  width: 60%;
}

.v3 .board_container {
  padding-top: 540px;
}

.list-instagram .board_content_cont .active .board_item .title a.view_sg {
  padding: 0px;
}

.list-youtube .board_content_cont .active .board_item .title a.view_yt img {
  -webkit-animation-name: cf4FadeInOut;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 4s;
  -moz-animation-name: cf4FadeInOut;
  -moz-animation-timing-function: ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-duration: 4s;
  -o-animation-name: cf4FadeInOut;
  -o-animation-timing-function: ease-in-out;
  -o-animation-iteration-count: infinite;
  -o-animation-duration: 4s;
  animation-name: cf4FadeInOut;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-duration: 4s;
}

@keyframes cf4FadeInOut {
  0% {
    opacity: 1;
  }
  17% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
  92% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(1) {
  animation-delay: 3s;
}

.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(2) {
  animation-delay: 2s;
}

.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(3) {
  animation-delay: 1s;
}

.list-youtube .board_content_cont .active .board_item .title a.view_yt img:nth-of-type(4) {
  animation-delay: 0;
}

.hasNotice #header {
  top: 55px !important;
}

.hasNotice .board_header {
  top: 55px !important;
}

.hasNotice .scroll_progress {
  top: 232px !important;
}

.hasNotice .board_container {
  padding-top: 465px !important;
}

.v3.hasNotice .board_container {
  padding-top: 545px !important;
}

.hasNotice .section.chart .board_container {
  padding-top: 0px !important;
}

.v3.hasNotice .section.chart .board_container {
  padding-top: 0px !important;
}

.vm.hasNotice .scroll_progress {
  top: 177px !important;
}

.vm.hasNotice .board_container {
  padding-top: 285px !important;
}

.vm.hasNotice .section.chart .board_container {
  padding-top: 0px !important;
}

.dot_area {
  z-index: -10;
  position: fixed;
  top: 140px;
  left: 50%;
  width: 1920px;
  height: 100%;
  transform: translateX(-50%);
}

.vm .dot_area {
  width: 100%;
  left: 0;
  right: 0;
  transform: none;
}

.dot_area [class^=dot_box] {
  position: absolute;
  top: 0;
  left: 50%;
  width: 100%;
  height: 100%;
  margin-left: -50%;
  transition: transform .3s ease-out;
  transform: translateZ(-1px);
}

.dot_area [class^=dot_box].dot_box2 {
  transform: translateZ(-2px);
}

.dot_area [class^=dot_box].dot_box3 {
  transform: translateZ(-3px);
}

.dot_area span[class^=dot] {
  position: absolute;
  display: block;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
}

.dot_area span[class^=dot].dot1 {
  background: #903262;
}

.dot_area span[class^=dot].dot2 {
  background: #eab1b9;
}

.dot_area span[class^=dot].dot3 {
  background: #f0aa70;
}

.dot_area span[class^=dot].dot4 {
  background: #9ec372;
}

.vm .dot_area span[class^=dot] {
  width: 5px;
  height: 5px;
}

html, body {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-touch-callout: none;
  min-height: 100%;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
}

.grid2 line {
  stroke: #000000;
  stroke-opacity: 0.2;
  /* shape-rendering: crispEdges; */
  stroke-width: 1px;
}

.grid line {
  stroke: #ffffff;
  stroke-opacity: 0.2;
  /* shape-rendering: crispEdges; */
  stroke-width: 1px;
}

.grid path {
  stroke-width: 0;
}

text {
  font-family: 'itc-avant-garde-gothic-pro',sans-serif;
}

.page_area {
  width: 400px;
  height: 50px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  margin-top: 30px;
}

.page_area button {
  position: absolute;
  top: 15px;
  background: transparent;
  border: 0;
  font-size: 14px;
  font-weight: bold;
}

.page_area .btnPrev {
  left: 0;
  cursor: pointer;
}

.page_area .btnNext {
  right: 0;
  cursor: pointer;
}

.page_area .board_paging {
  position: relative;
  top: 0px;
  width: 300px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding-left: 0;
}

.page_area .board_paging .page-count {
  display: inline-block;
  margin: 5px 10px;
}

.vm .page_area {
  width: 200px;
  height: 40px;
  position: relative;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  overflow: hidden;
  margin-top: 30px;
}

.vm .page_area button {
  position: absolute;
  top: 10px;
  background: transparent;
  border: 0;
  font-size: 14px;
  font-weight: bold;
}

.vm .page_area .btnPrev {
  left: 0;
  cursor: pointer;
}

.vm .page_area .btnNext {
  right: 0;
  cursor: pointer;
}

.vm .page_area .board_paging {
  position: relative;
  top: 0px;
  width: 120px;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
  padding-left: 0;
}

.vm .page_area .board_paging .page-count {
  display: inline-block;
  margin: 5px 5px;
}

.spinner {
  position: fixed;
  width: 100% !important;
  background: white;
  height: 100% !important;
  /* top: 50%;
left: 50%;
margin-left: -50px; 
margin-top: -50px; 
text-align:center; */
  z-index: 10000000000;
  overflow: auto;
  width: 100px;
  /* width of the spinner gif */
  height: 102px;
  /*hight of the spinner gif +2px to fix IE8 issue */
  /* background: url(/@resources/images/loading3.gif) center no-repeat #fff0; */
}

@keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@-webkit-keyframes lds-ellipsis3 {
  0%, 25% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  50% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  75% {
    left: 100px;
  }
  100% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis2 {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  25%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

@-webkit-keyframes lds-ellipsis {
  0% {
    left: 32px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  25% {
    left: 32px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    left: 100px;
  }
  75% {
    left: 168px;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  100% {
    left: 168px;
    -webkit-transform: scale(0);
    transform: scale(0);
  }
}

.lds-ellipsis {
  /* position: relative; */
  top: 45%;
  position: absolute;
  left: 45%;
}

.lds-ellipsis > div {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
}

.lds-ellipsis div > div {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: #f00;
  position: absolute;
  top: 100px;
  left: 32px;
  -webkit-animation: lds-ellipsis 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
}

.lds-ellipsis div:nth-child(1) div {
  -webkit-animation: lds-ellipsis2 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis2 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #ff354e;
}

.lds-ellipsis div:nth-child(2) div {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
  background: #ff354e;
}

.lds-ellipsis div:nth-child(3) div {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
  background: #ff354e;
}

.lds-ellipsis div:nth-child(4) div {
  -webkit-animation-delay: 0s;
  animation-delay: 0s;
  background: #ff354e;
}

.lds-ellipsis div:nth-child(5) div {
  -webkit-animation: lds-ellipsis3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  animation: lds-ellipsis3 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite forwards;
  background: #ff354e;
}

.lds-ellipsis {
  width: 56px !important;
  height: 56px !important;
  -webkit-transform: translate(-28px, -28px) scale(0.28) translate(28px, 28px);
  transform: translate(-28px, -28px) scale(0.28) translate(28px, 28px);
}

.mail_text strong img {
  width: 26px;
  position: relative;
  top: 4px;
  left: 5px;
}

.vm .mail_text strong img {
  width: 18px;
  left: 3px;
}

.license {
  position: absolute;
  bottom: 60px;
  right: 60px;
}

.license img {
  width: 120px;
}

.vm .license {
  position: absolute;
  right: 5px;
  bottom: 37px;
}

.vm .license img {
  width: 60px;
  margin-right: 10px;
}

.copyright > a {
  color: #7a7a7a !important;
}

.spaceoddity {
  position: absolute;
  bottom: 20px;
  left: 0px;
}

.spaceoddity img {
  width: 120px;
}

.vm .spaceoddity {
  bottom: 55px;
  left: auto;
}

.vm .spaceoddity img {
  width: 75px;
  margin-left: 4px;
}

.jquery-modal.blocker.current {
  z-index: 1000;
}

#youtubeModal {
  width: 96%;
}

.notice_area {
  top: 0px;
  max-width: 1920px;
  background: #ff354e;
  color: white;
  position: fixed;
  width: 100%;
  height: 55px;
  z-index: 999999999;
  font-size: 14px;
}

.vm .notice_area {
  font-size: 12px;
}

.notice_category {
  color: white;
  float: left;
  margin-right: 15px;
  margin-top: 21px;
  margin-left: 61px;
  font-weight: bold;
}

.vm .notice_category {
  margin-left: 20px;
  margin-top: 22px;
}

.notice_title {
  color: white;
  float: left;
  margin-top: 21px;
}

.vm .notice_title {
  margin-top: 22px;
}

#notice_x {
  float: right;
  color: #fff;
  position: relative;
  top: 20px;
  right: 61px;
  z-index: 9999999999;
  cursor: pointer;
}

.vm #notice_x {
  top: 20px;
  right: 20px;
}
